<div class="text-center" data-tt-tour="UserRegister">
	<h1 class="tt-start-panel-title mb-3">Register</h1>
	<!-- Verify email View -->
	<form
		id="requestEmailVerificationForm"
		[formGroup]="requestEmailVerificationForm"
		autocomplete="off"
		(ngSubmit)="onVerifyButtonPress()"
		[hidden]="showRegistrationUI"
		novalidate
		name="requestEmailVerificationForm">
		<fieldset [disabled]="waitingForServer">
			<p-inputGroup
				id="email"
				data-tt-tour="UserRequestEmailVerification-Input-Email"
				tp="Enter your e-mail address and press Verify to begin">
				<p-inputGroupAddon>
					<span class="tt-icon-email fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="emailInput" class="d-none">E-mail Address</label>
				<input
					formControlName="username"
					id="emailInput"
					type="email"
					pInputText
					class="w-100"
					placeholder="E-mail Address"
					autocomplete="email"
					[ngClass]="{ 'is-invalid' : username.dirty && username.invalid }"/>
			</p-inputGroup>
			<p-message
				*ngIf="username.dirty && username.invalid"
				severity="error"
				text="Please enter a valid e-mail address"
				styleClass="w-100">
			</p-message>
			<button
				class="mt-3 tt-btn-m tt-btn-create w-100"
				data-tt-tour="UserRegister-Button-Verify"
				id="verify-button"
				type="submit"
				aria-label="Verify"
				value="Verify"
				[disabled]="requestEmailVerificationForm.invalid">
				Verify
			</button>
		</fieldset>
	</form>
	<!-- Register View -->
	<form
		id="registerForm"
		[formGroup]="registerForm"
		novalidate
		name="registerForm"
		[hidden]="!showRegistrationUI"
		(ngSubmit)="onRegisterButtonPress()">
		<fieldset [disabled]="waitingForServer">
			<label for="nameFirstInput" class="d-none">First name</label>
			<p-inputGroup
				id="nameFirst"
				data-tt-tour="UserRegister-Input-NameFirst"
				tp="Enter your first (given) name">
				<p-inputGroupAddon>
					<span class="tt-icon-account fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<input
					formControlName="nameFirst"
					id="nameFirstInput"
					type="text"
					pInputText
					class="w-100"
					[ngClass]="{'is-invalid' : nameFirst.dirty && nameFirst.invalid }"
					autocomplete="given-name"
					placeholder="First name"/>
			</p-inputGroup>
			<p-message
				*ngIf="nameFirst.dirty && nameFirst.invalid"
				severity="error"
				text="Enter your first (given) name"
				styleClass="w-100">
			</p-message>
			<label for="nameLastInput" class="d-none">Last name</label>
			<p-inputGroup
				id="nameLast"
				class="mt-3"
				data-tt-tour="UserRegister-Input-NameLast"
				tp="Enter your last (family) name">
				<p-inputGroupAddon>
					<span class="tt-icon-account fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<input
					formControlName="nameLast"
					id="nameLastInput"
					name="nameLast"
					type="text"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid' : nameLast.dirty && nameLast.invalid }"
					autocomplete="family-name"
					placeholder="Last name"/>
			</p-inputGroup>
			<p-message
				*ngIf="nameLast.dirty && nameLast.invalid"
				severity="error"
				text="Enter your last (family) name"
				styleClass="w-100">
			</p-message>
			<label for="organizationNameInput" class="d-none">Organization name</label>
			<p-inputGroup
				id="organizationName"
				class="mt-3"
				data-tt-tour="UserRegister-Input-OrganizationName"
				tp="Optionally enter a name for your TaskTrain workspace">
				<p-inputGroupAddon>
					<span class="tt-icon-organization fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<input
					formControlName="organizationName"
					id="organizationNameInput"
					name="organizationName"
					type="text"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid' : organizationName.dirty && organizationName.invalid }"
					autocomplete="organization"
					placeholder="Organization Name (optional)"/>
			</p-inputGroup>
			<label for="usernameInput" class="d-none">E-mail Address</label>
			<p-inputGroup
				id="username"
				class="mt-3"
				data-tt-tour="UserRegister-Input-Username"
				tp="Your email address is your username">
				<p-inputGroupAddon>
					<span class="tt-icon-email fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<div class="w-100 p-input-icon-right">
					<input
						formControlName="username"
						id="usernameInput"
						name="username"
						type="email"
						pInputText
						style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
						class="w-100"
						[ngClass]="{ 'is-invalid': username.dirty && username.invalid }"
						autocomplete="email"
						readonly
						placeholder="E-mail Address"/>
					<i
						class="pi pi-times-circle"
						(click)="showRegistrationUI = false"
						tp="Restart email address verification">
					</i>
				</div>
			</p-inputGroup>
			<p-message
				*ngIf="username.dirty && username.invalid"
				severity="error"
				text="Please enter a valid e-mail address"
				styleClass="w-100">
			</p-message>
			<p-inputGroup
				id="password"
				class="mt-3"
				data-tt-tour="UserRegister-Input-Password"
				tp="Enter a complex password of 11-222 characters">
				<p-inputGroupAddon>
					<span class="tt-icon-password fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="passwordInput" class="d-none">Password</label>
				<input
					formControlName="password"
					id="passwordInput"
					name="password"
					type="password"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid': password.dirty && password.invalid }"
					autocomplete="password"
					placeholder="Password"/>
			</p-inputGroup>
			<p-message
				*ngIf="password.dirty && password.invalid"
				severity="error"
				[text]="password.errors?.minlength ? 'Password must be 11 characters or longer' :
				password.errors?.maxlength ? 'Password must be 222 characters or shorter' :
				password.errors?.passwordComplexity ? password.errors.passwordComplexity : ''"
				styleClass="w-100">
			</p-message>
			<p-inputGroup
				id="passwordConfirm"
				class="mt-3"
				data-tt-tour="UserRegister-Input-PasswordConfirm"
				tp="Re-enter your password to confirm">
				<p-inputGroupAddon>
					<span class="tt-icon-password fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="passwordConfirmInput" class="d-none">Confirm Password</label>
				<input
					formControlName="passwordConfirm"
					id="passwordConfirmInput"
					name="passwordConfirm"
					type="password"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid': passwordConfirm.dirty && password.invalid }"
					autocomplete="password"
					placeholder="Confirm Password"/>
			</p-inputGroup>
			<p-message
				*ngIf="passwordConfirm.dirty && passwordConfirm.invalid"
				severity="error"
				text="Password and Confirm password must match"
				styleClass="w-100">
			</p-message>
			<p-inputGroup
				id="verificationCode"
				class="mt-3"
				data-tt-tour="UserRegister-Input-VerificationCode"
				tp="Enter the verification code sent to <{{username.value}}>">
				<p-inputGroupAddon>
					<span class="tt-icon-verification fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="verificationCodeInput" class="d-none">Verification Code</label>
				<input
					formControlName="verificationCode"
					id="verificationCodeInput"
					type="number"
					pInputText
					class="w-100"
					placeholder="Verification code (sent to <{{username.value}}>)"/>
			</p-inputGroup>
			<p-message
				*ngIf="verificationCode.dirty && verificationCode.invalid"
				severity="error"
				text="Enter verification code sent to <{{username.value}}>"
				styleClass="w-100">
			</p-message>
			<p-card class="mt-3" data-tt-tour="UserRegister-Button-PrivacyPolicy" styleClass="mt-3 p-1">
				<ng-template pTemplate="header">
					<div class="text-center">
						By registering, you agree to the TaskTrain
						<a href="https://TaskTrain.app/privacy/" target="_blank" rel="noopener noreferrer" tabindex="1">
							Privacy Policy
						</a>
						&
						<a href="https://TaskTrain.app/terms/" target="_blank" rel="noopener noreferrer" tabindex="2">
							Terms of Service
						</a>.
					</div>
				</ng-template>
			</p-card>
			<button
				id="register-button"
				data-tt-tour="UserRegister-Button-Register"
				class="mt-3 tt-btn-m tt-btn-create w-100"
				type="submit"
				value="Register"
				aria-label="Register"
				[disabled]="registerForm.invalid">
				<span
					[ngClass]="{ 'fa fa-lg me-1': true, 'fa-sign-in': !waitingForServer, 'fa-spinner fa-spin': waitingForServer }"
					aria-hidden="true">
				</span>
				Register
			</button>
		</fieldset>
	</form>
</div>
