/* eslint-disable @typescript-eslint/naming-convention */
import { OperationDefinitionNode } from 'graphql';
import { gql } from 'apollo-angular';

import { IAccount } from '@tasktrain/shared';


export const AccountRead_Fields = gql`
	fragment AccountFields on Account {
		_id
		emailAddress
		organizationRole
		isVerified
		displayName
		emailNotificationTypeList
		organization {
			_id
			name
			description
			sectorList
			billingInfo {
			  plan {
					_id
					name
				}
			}
		}
	}
`;

export const AccountRead_Query = gql`
	query AccountRead($organizationId: ID, $accountId: ID){
		AccountRead(organizationId: $organizationId, accountId: $accountId){
			...AccountFields
		}
	}
	${AccountRead_Fields}
`;

export const AccountRead_Name = (AccountRead_Query.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountRead_RequestVariables {
	accountId?: string;
	organizationId?: string;
}

export interface IAccountRead_ResponseData {
	AccountRead: IAccount;
}

