import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../../procedure/procedure-status.type';


export class ProcedureStepUpdateDescriptorsPermission {
	public isAuthorized: boolean;
	public nameMessage: string;
	public detailMessage: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType = startCase(DomainEntityTypeName.ProcedureStep);
		const parentEntityType = startCase((DomainEntityTypeName.Procedure));
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;
		const mayUpdate = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);

		this.isAuthorized = isEditable && mayUpdate;
		this.nameMessage = this.isAuthorized ? `Rename ${entityType}` :
			mayUpdate ? `${procedureStatus} ${entityType} may not be renamed` :
				`Manual Owner or Manager Role required to rename a ${entityType}`;
		this.detailMessage = this.isAuthorized ? `Enter details/instructions unique to this ${entityType}` :
			mayUpdate ? `Unarchive ${parentEntityType} to update detail` :
				'Manual Owner or Manager Role required to update detail';
	}
}
