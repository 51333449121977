/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUserSignInResult } from '@tasktrain/shared';


export const UserSignIn_Mutation = gql`
	mutation UserSignIn($username: String!, $password: String!) {
		UserSignIn(username: $username, password: $password) {
			sessionId
			accessToken
			refreshToken
			user {
				_id
			}
		}
	}
`;

export const UserSignIn_Name = (UserSignIn_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserSignIn_RequestVariables {
	username: string;
	password: string;
}

export interface IUserSignIn_ResponseData {
	UserSignIn: IUserSignInResult;
}
