/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAccount } from '@tasktrain/shared';


export const AccountUpdateDisplayName_Mutation = gql`
	mutation AccountUpdateDisplayName($organizationId: ID!, $displayName: String!) {
		AccountUpdateDisplayName(organizationId: $organizationId, displayName: $displayName) {
			_id
			displayName
		}
	}
`;

export const AccountUpdateDisplayName_Name = (AccountUpdateDisplayName_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateDisplayName_RequestVariables {
	organizationId: string;
	displayName: string;
}

export interface IAccountUpdateDisplayName_ResponseData {
	AccountUpdateDisplayName: IAccount;
}
