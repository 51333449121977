/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

export const UserDelete_Mutation = gql`
	mutation UserDelete {
		UserDelete
	}
`;

export const UserDelete_Name = (UserDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserDelete_ResponseData {
	UserDelete: boolean;
}
