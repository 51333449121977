import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ManualUpdateProcedureListOrderPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole) {
		const entityChildType: string = startCase(DomainEntityTypeName.Procedure);

		this.isAuthorized = manualRole === ManualRole.Owner || manualRole === ManualRole.Manager;
		this.message = this.isAuthorized ? `Reorder ${entityChildType}` :
			`Manual Owner or Manager permission required to reorder a ${entityChildType}`;
	}
}
