import { Injectable } from '@angular/core';
import { InMemoryCache, TypePolicies } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash-unified';

import { Article, DomainEntityTypeName, GQLQueryName, GQLSchemaRootName, IEntityActivity, IPagination } from '@tasktrain/shared';

import { MutationInProgressListRead_Query } from '../../gql-operations/_client/mutation-in-progress-list-read.query';
import { ViewStateParameters } from '../view-state/view-state-parameters.model';
import { ViewStateParametersRead_Query } from '../../gql-operations/_client/view-state-parameters-read.query';


@Injectable({
	providedIn: 'root',
})
export class GQLCacheService {
	private inMemoryCache = new InMemoryCache({
		canonizeResults: true, // Disable on any query where empty array equality is problematic: https://github.com/apollographql/apollo-client/pull/8822
		typePolicies: GQLCacheService.typePolicies(),
	});

	public constructor(private apollo: Apollo) {
		this.inMemoryCache.reset();
	}

	/** Generic logic for TypePolicy field merge for skip/limit pagination
	 * https://www.apollographql.com/docs/react/pagination/core-api/#designing-the-merge-function
	 */
	private static skipLimitPaginationMerge(
		existingQueryData: unknown[] = [],
		incomingQueryData: unknown[],
		{ args: { pagination: { skip = 0, limit = 10 } } }: { args: { pagination?: IPagination } },
	) {
		const merged = existingQueryData ? existingQueryData.slice(0) : []; // Slice because the existing data is immutable, and frozen in development.
		for (let i = 0; i < incomingQueryData.length; ++i) {
			merged[skip * limit + i] = incomingQueryData[i];
		}
		return merged;
	}

	/** Generic logic for TypePolicy field merge for skip/limit pagination
	 * https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
	 */
	private static skipLimitPaginationRead(
		existingQuerydata: IEntityActivity[] = [],
		{ args: { pagination: { skip = 0, limit = 10 } } }: { args: { pagination?: IPagination } },
	) {
		return existingQuerydata?.slice(skip * limit, skip * limit + limit);
	}

	private static typePolicies(): TypePolicies {
		return {
			[GQLSchemaRootName.Query]: {
				fields: {
					[GQLQueryName.ArticleListRead]: { // @ToDo: refactor this operation to use skip/limit pagination & replace read/merge with static methods
						keyArgs: ['articleType'],
						merge: (existingQueryData: Article[] = [], incomingQueryData: Article[], { args }) => {
							const offset = (args.page - 1) * args.pageSize;
							const mergedData = existingQueryData ? cloneDeep(existingQueryData) : [];
							for (let i = 0; i < incomingQueryData.length; ++i) {
								mergedData[offset + i] = incomingQueryData[i];
							}
							return mergedData;
						},
						read: (existingQueryData: Article[] = [], { args }) => {
							const offset = (args.page - 1) * args.pageSize;
							return existingQueryData?.slice(offset, args.pageSize);
						},
					},
				},
			},
			[DomainEntityTypeName.Assignment]: {
				fields: {
					activityList: {
						keyArgs: false,
						merge: GQLCacheService.skipLimitPaginationMerge,
						read: GQLCacheService.skipLimitPaginationRead,
					},
				},
			},
			[DomainEntityTypeName.AssignmentTask]: {
				fields: {
					activityList: {
						keyArgs: false,
						merge: GQLCacheService.skipLimitPaginationMerge,
						read: GQLCacheService.skipLimitPaginationRead,
					},
				},
			},
			[DomainEntityTypeName.Procedure]: {
				fields: {
					activityList: {
						keyArgs: false,
						merge: GQLCacheService.skipLimitPaginationMerge,
						read: GQLCacheService.skipLimitPaginationRead,
					},
				},
			},
			[DomainEntityTypeName.ProcedureStep]: {
				fields: {
					activityList: {
						keyArgs: false,
						merge: GQLCacheService.skipLimitPaginationMerge,
						read: GQLCacheService.skipLimitPaginationRead,
					},
				},
			},
		};
	}

	/** Call to initialize cache at application start */
	public initializeCache(): InMemoryCache {
		this.inMemoryCache.writeQuery({
			query: ViewStateParametersRead_Query,
			data: { ViewStateParametersRead: new ViewStateParameters() },
		});
		this.inMemoryCache.writeQuery({
			query: MutationInProgressListRead_Query,
			data: { MutationInProgressListRead: [] },
		});
		return this.inMemoryCache;
	}

	/** Call to reinitialize cache on UserSignOut to ensure data security
	 *  https://www.apollographql.com/docs/angular/recipes/authentication.html#login-logout
	 */
	public reinitializeCache(): void {
		this.apollo.client.resetStore()
			.then(() => {
				this.initializeCache();
			})
			.catch((error: unknown) => {
				this.initializeCache();
			});
	}
}
