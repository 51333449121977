/** [common HTTP Status Codes](https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html) with TaskTrain-specific usage */
export enum ResponseStatus {
	/** Request other than create mutation successful */
	Success = 200,
	/** Create mutation successful */
	Created = 201,
	/** Request validation failed */
	BadRequest = 400,
	/** Unauthenticated */
	Unauthorized = 401,
	/** Unauthorized */
	Forbidden = 403,
	/** Database miss */
	NotFound = 404,
	Timeout = 408,
	Gone = 410,
	/** Unexpected/unknown error */
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
}
