import { Component } from '@angular/core';
import { Confirmation, ConfirmationService } from 'primeng/api';

interface IConfirmationStyles {
	acceptStyles?: {
		icon?: string;
		button?: string;
	};
	rejectStyles?: {
		icon?: string;
		button?: string;
	};
}

declare module 'primeng/api/confirmation' {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Confirmation extends IConfirmationStyles {
	}
}

@Component({
	selector: 'tt-confirmation-dialog',
	styleUrls: ['confirmation-dialog.component.scss'],
	templateUrl: 'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
	public buttonStyleClasses: IConfirmationStyles = {
		acceptStyles: {
			icon: 'tt-icon-delete',
			button: 'tt-btn-delete'
		},
		rejectStyles: {
			icon: 'tt-icon-cancel',
			button: 'tt-btn-cancel'
		}
	};

	public constructor(private confirmationService: ConfirmationService) {
		this.confirmationService.requireConfirmation$.subscribe((message: Confirmation) => {
			if (message.acceptStyles) {
				this.buttonStyleClasses.acceptStyles = message.acceptStyles;
			}
			if (message.rejectStyles) {
				this.buttonStyleClasses.rejectStyles = message.rejectStyles;
			}
		});
	}
}
