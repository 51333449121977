import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../procedure-status.type';

export class ProcedureUpdateCategorizationPermission {
	public isAuthorized: boolean;
	public sectorListMessage: string;
	public functionListMessage: string;
	public keywordListMessage: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const mayUpdate: boolean = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = mayUpdate && isEditable;
		this.keywordListMessage = mayUpdate && isEditable ? `Add tags to categorize ${entityType} content by topic keyword` :
			isEditable ? `Manual Owner or Manager Role required to categorize ${entityType} content` :
				`${procedureStatus} ${entityType} may not be modified.`;
		this.sectorListMessage = mayUpdate && isEditable ? `Add tags to categorize ${entityType} by economic sector` :
			isEditable ? `Manual Owner or Manager Role required to categorize ${entityType} by economic sector` :
				`${procedureStatus} ${entityType} may not be modified.`;
		this.functionListMessage = mayUpdate && isEditable ? `Add tags to categorize ${entityType} by organizational function` :
			isEditable ? `Manual Owner or Manager Role required to categorize ${entityType} by organizational function` :
				`${procedureStatus} ${entityType} may not be modified.`;
	}
}
