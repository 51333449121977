import { ManualRole } from '../manual.types';


export class ManualUpdateCategorizationPermission {
	public isAuthorized: boolean;
	public sectorListMessage: string;
	public functionListMessage: string;
	public keywordListMessage: string;

	public constructor(manualRole: ManualRole) {
		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		this.sectorListMessage = this.isAuthorized ? 'Add tags to categorize by economic sector' :
			'Manual Owner or Manager Role required to categorize by economic sector';
		this.functionListMessage = this.isAuthorized ? 'Add tags to categorize by organizational function' :
			'Manual Owner or Manager Role required to categorize by organizational function';
		this.keywordListMessage = this.isAuthorized ? 'Add tags to categorize content by topic keyword' :
			'Manual Owner or Manager Role required to categorize content';
	}
}
