/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAssignmentWithAccountViewStatus } from '@tasktrain/shared';


export const AccountUpdateAssignmentArchiveStatus_Mutation = gql`
	mutation AccountUpdateAssignmentArchiveStatus($accountId: ID!, $assignmentId: ID!, $isArchived: Boolean!) {
		AccountUpdateAssignmentArchiveStatus(accountId: $accountId, assignmentId: $assignmentId, isArchived: $isArchived) {
			_id
			viewStatus
		}
	}
`;

export const AccountUpdateAssignmentArchiveStatus_Name = (AccountUpdateAssignmentArchiveStatus_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateAssignmentArchiveStatus_RequestVariables {
	accountId: string;
	assignmentId: string;
	isArchived: boolean;
}

export interface IAccountUpdateAssignmentArchiveStatus_ResponseData {
	AccountUpdateAssignmentArchiveStatus: IAssignmentWithAccountViewStatus;
}
