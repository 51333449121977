/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserVerifyUsername_Mutation = gql`
	mutation UserVerifyUsername($verificationToken: String!){
		UserVerifyUsername(verificationToken: $verificationToken)
	}
`;

export const UserVerifyUsername_Name = (UserVerifyUsername_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserVerifyUsername_RequestVariables {
	verificationToken: string;
}

export interface IUserVerifyUsername_ResponseData {
	UserVerifyUsername: boolean;
}
