import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureUpdateProcedureStepListOrderPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const entityChildType: string = startCase(DomainEntityTypeName.ProcedureStep);

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		this.message = this.isAuthorized ? `Reorder ${entityType}` : `Manual Owner or Manager Role required to reorder a ${entityChildType}`;
	}
}
