import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ManualDeletePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);

		this.isAuthorized = manualRole === ManualRole.Owner;
		this.message = this.isAuthorized ? `Delete ${entityType}` : `${entityType}  Owner Role required to Delete a ${entityType}`;
	}
}
