import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { OrganizationRole } from '../../../common';

export class ManualUpdateMemberRolePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(requestorManualRole: ManualRole, requestorOrganizationRole: OrganizationRole, manualHasRemainingOwner: boolean) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);
		this.isAuthorized = manualHasRemainingOwner && (requestorManualRole === ManualRole.Owner || requestorOrganizationRole === OrganizationRole.Owner);
		this.message = this.isAuthorized ? `Change Member's ${entityType} access level` : `${entityType} Owner Role required to change Member's ${entityType} access level`;
		switch (true) {
			case this.isAuthorized:
				this.message = `Change Member's ${entityType} access level`;
				break;
			case manualHasRemainingOwner:
				this.message = `Only ${entityType} ${ManualRole.Owner} or Organization ${OrganizationRole.Owner} may change Member Role.`;
				break;
			default:
				this.message = `A ${entityType} must have at least 1 ${ManualRole.Owner}.`;
				break;
		}
	}
}
