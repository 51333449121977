import { startCase } from 'lodash-unified';

import { OrganizationRole } from '../../organization/organization-role.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class AccountRequestVerificationPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(organizationRole: OrganizationRole, isVerified: boolean) {
		const entityType = startCase(DomainEntityTypeName.Organization);

		this.isAuthorized = organizationRole === OrganizationRole.Owner && isVerified === false;
		this.message = this.isAuthorized ? 'Member has not verified account. Press to send reminder' : (organizationRole === OrganizationRole.Owner ? 'Account e-mail address verified.' : 'Only Owner may request account verification.');
	}
}
