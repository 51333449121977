/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUser, UserEventName } from '@tasktrain/shared';


export const UserAddEvent_Mutation = gql`
	mutation UserAddEvent($name: String!, $dateTime: DateTime){
		UserAddEvent(name: $name, dateTime: $dateTime) {
			_id
			eventList {
				name
				dateTime
			}
		}
	}
`;

export const UserAddEvent_Name = (UserAddEvent_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserAddEvent_RequestVariables {
	name: UserEventName;
	dateTime?: Date;
}

export interface IUserAddEvent_ResponseData {
	UserUpdateName: IUser;
}
