import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import { GraphQLError } from 'graphql';
import { startCase } from 'lodash-unified';

import { UserNotificationMessage, UserNotificationType } from '../../core/services/message-service/user-notification-message.model';
import { MessageService } from '../../core/services/message-service/message.service';
import { validateFormControlPasswordComplexity } from '../../core/methods/form-validation/validate-form-control-password-complexity.method';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { IUserSetPassword_ResponseData } from '../../core/gql-operations/user/user-set-password.mutation';


@Component({
	selector: 'tt-user-set-password',
	templateUrl: 'user-set-password.component.html',
	styleUrls: ['user-set-password.component.scss'],
})
export class UserSetPasswordComponent implements OnInit {
	public waitingForServer = false;
	public title: string;
	public password = new FormControl<string>('', [Validators.minLength(11), Validators.maxLength(222), validateFormControlPasswordComplexity]);
	public passwordConfirm = new FormControl<string>('', [Validators.required, this.validateFormControlPasswordConfirm.bind(this)]);
	public updatePasswordForm = new FormGroup({
		password: this.password,
		passwordConfirm: this.passwordConfirm,
	});
	private resetReason: string;

	public constructor(
		private viewRouter: Router,
		private activatedRoute: ActivatedRoute,
		private messageService: MessageService,
		private userMutationsService: UserMutationsService,
	) {
	}

	public ngOnInit(): void {
		this.resetReason = this.activatedRoute.snapshot.routeConfig.path.split('/')[0];
		this.resetReason === AuthRoutingPaths.EnrollAccount ? this.title = 'Set Password' : this.title = 'Reset Password';
	}

	private validateFormControlPasswordConfirm(formControl: FormControl): ValidationErrors | null {
		return formControl.value === this.password.value ? null : { passwordConfirm: 'Password and Confirm must match' };
	}

	public onSetPasswordButtonPress(): void {
		if (this.updatePasswordForm.valid && !this.waitingForServer) {
			this.waitingForServer = true;
			this.userMutationsService.setPassword({
				password: this.passwordConfirm.value,
				token: this.activatedRoute.snapshot.params.token,
			},
			).subscribe({
				next: ({ data }: FetchResult<IUserSetPassword_ResponseData>): void => {
					this.messageService.publish(new UserNotificationMessage('Password reset successfully', UserNotificationType.Success));
					this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.SignIn]);
				},
				error: (error: unknown): void => {
					const errorMessage = (error as GraphQLError).message;
					const notificationMessage = errorMessage === 'GraphQL error: Document not found: User'
						? `${startCase(this.resetReason
							.replace('-', ' '))} token already used or invalid`
						: errorMessage;
					this.messageService.publish(new UserNotificationMessage(notificationMessage, UserNotificationType.Error));
					this.viewRouter.navigate([AppRoutingPaths.Main]);
				},
				complete: (): void => {
					this.waitingForServer = false;
				},
			});
		}
	}
}
