/* eslint-disable @stylistic/quote-props,@stylistic/quotes,@stylistic/indent */
export const environment = {
  "version": "1.20.4",
  "production": false,
  "serverSchemeSecure": true,
  "serverHost": "staging-server.tasktrain.app",
  "serverPort": "443",
  "graphQLpath": "/graphql",
  "ContentBucketKey": "AKIAVPFWEPFCBGI43CHJ",
  "ContentBucketSecret": "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",
  "ContentBucketRegion": "us-east-2",
  "BedrockAccessKey": "AKIAVPFWEPFCFHYIB5GS",
  "BedrockSecretKey": "kncnL9I0lAIf4mNIwwOQQN2vTRw9sp5a7+A+fDF",
  "BedrockRegion": "us-east-1",
  "ContentBucketName": "tasktrain-content-staging",
  "GoogleAnalyticsCode": "G-CEQ1C9DJEN",
  "HelpCrunchAppId": 2,
  "HelpCrunchSecret": "87zEUc9ZyEBhGYOk5ZzFsltV414Qocss7ZbzG/MA4BKXnHt+MzqJawr6xPVFC3driAG5wAdmU0l5926I0rjHeQ==",
  "StripeKey": "pk_test_Ts4QUeE4Gp6ykLU186N9JeqN00OaAhnwXC"
};
