/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUser } from '@tasktrain/shared';

import { UserRead_Fields } from './user-read.query';
import { AccountRead_Fields } from '../account/account-read.query';


export const UserRead$AccountList_Query = gql`
	query UserRead_AccountList {
		UserRead {
			...UserFields
			accountList {
				...AccountFields
				organization {
					billingInfo {
						subscriptionStartDate
						paymentStatus
						plan {
							_id
							unitPrice
							trialDays
						}
					}
				}
			}
		}
	}
	${UserRead_Fields}
	${AccountRead_Fields}
`;

export const UserRead$AccountList_Name = (UserRead$AccountList_Query.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserRead$AccountList_RequestVariables {
}

export interface IUserRead$AccountList_ResponseData {
	UserRead: IUser;
}
