import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Apollo, MutationResult } from 'apollo-angular';

import { DomainEntityTypeName } from '@tasktrain/shared';

import { IUserUpdateUsername_RequestVariables, IUserUpdateUsername_ResponseData, UserUpdateUsername_Mutation } from './user-update-username.mutation';
import { IUserUpdateName_RequestVariables, IUserUpdateName_ResponseData, UserUpdateName_Mutation } from './user-update-name.mutation';
import { IUserRequestVerification_ResponseData, UserRequestVerification_Mutation } from './user-request-verification.mutation';
import { IUserUpdatePassword_RequestVariables, IUserUpdatePassword_ResponseData, UserUpdatePassword_Mutation } from './user-update-password.mutation';
import { IUserSendPasswordReset_RequestVariables, IUserSendPasswordReset_ResponseData, UserSendPasswordReset_Mutation } from './user-send-password-reset.mutation';
import { IUserRegister_RequestVariables, IUserRegister_ResponseData, UserRegister_Mutation } from './user-register.mutation';
import { UserNotificationMessage, UserNotificationType } from '../../services/message-service/user-notification-message.model';
import { MessageService } from '../../services/message-service/message.service';
import { getGQLErrorMessage } from '../../methods/get-gql-error-message.method';
import { GQLClientService } from '../../services/gql/gql-client.service';
import { IUserAuthorizeOAuthClient_RequestVariables, IUserAuthorizeOAuthClient_ResponseData, UserAuthorizeOAuthClient_Mutation } from './user-authorize-oauth-client.mutation';
import { IUserVerifyUsername_RequestVariables, IUserVerifyUsername_ResponseData, UserVerifyUsername_Mutation } from './user-verify-username.mutation';
import { IUserSetPassword_RequestVariables, IUserSetPassword_ResponseData, UserSetPassword_Mutation } from './user-set-password.mutation';
import { IUserSignOut_RequestVariables, IUserSignOut_ResponseData, UserSignOut_Mutation } from './user-sign-out.mutation';
import { AppRoutingPaths, AuthRoutingPaths } from '../../../app-routing.module';
import { UserSignOutMessage } from '../../services/message-service/user-sign-out-message.model';
import { IUserSignIn_RequestVariables, IUserSignIn_ResponseData, UserSignIn_Mutation } from './user-sign-in.mutation';
import { AuthenticationService } from '../../services/authentication.service';
import { IUserAddEvent_RequestVariables, IUserAddEvent_ResponseData, UserAddEvent_Mutation } from './user-add-event.mutation';
import { AnalyticsService } from '../../services/analytics.service';
import { GQLCacheService } from '../../services/gql/gql-cache.service';
import { IUserAuthorizeRegistration_RequestVariables, IUserAuthorizeRegistration_ResponseData, UserAuthorizeRegistration_Mutation } from './user-authorize-registration.mutation';
import { IUserDelete_ResponseData, UserDelete_Mutation } from './user-delete.mutation';


@Injectable({
	providedIn: 'root',
})
export class UserMutationsService {
	public constructor(
		private apollo: Apollo,
		private messageService: MessageService,
		private viewRouter: Router,
		private gqlClientService: GQLClientService,
		private gqlCacheService: GQLCacheService,
		private authenticationService: AuthenticationService,
		private analyticsService: AnalyticsService,
	) {
	}

	public register(mutationVariables: IUserRegister_RequestVariables): Observable<MutationResult<IUserRegister_ResponseData>> {
		return this.apollo.mutate<IUserRegister_ResponseData, IUserRegister_RequestVariables>({
			mutation: UserRegister_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap(({ data: { UserRegister } }) => {
				this.notifyUser('Thank You for Registering! Press Sign In to Continue.', UserNotificationType.Success);
				this.analyticsService.logAction('sign_up', DomainEntityTypeName.User, UserRegister._id);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public signIn(mutationVariables: IUserSignIn_RequestVariables): Observable<MutationResult<IUserSignIn_ResponseData>> {
		return this.apollo.mutate<IUserSignIn_ResponseData, IUserSignIn_RequestVariables>({
			mutation: UserSignIn_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap(({ data: { UserSignIn } }) => {
				void this.authenticationService.accountsClient.setTokens({ accessToken: UserSignIn.accessToken, refreshToken: UserSignIn.refreshToken });
				this.notifyUser('Sign in success!', UserNotificationType.Success);
				this.analyticsService.logAction('login', DomainEntityTypeName.User, UserSignIn.user._id);
			}),
			catchError((error: unknown) => {
				return observableThrowError(() => error);
			}),
		);
	}

	public signOut(): Observable<MutationResult<IUserSignOut_ResponseData>> {
		return this.apollo.mutate<IUserSignOut_ResponseData, IUserSignOut_RequestVariables>({
			mutation: UserSignOut_Mutation,
		}).pipe(
			tap((data) => {
				void this.authenticationService.accountsClient.clearTokens();
				this.messageService.publish(new UserSignOutMessage());
				this.notifyUser('Sign out success!', UserNotificationType.Success);
				void this.viewRouter.navigate([AppRoutingPaths.Auth])
					.then((navigationResult) => {
						void this.gqlClientService.closeWebSocket();
						this.gqlCacheService.reinitializeCache();
					});
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public updateName(mutationVariables: IUserUpdateName_RequestVariables): Observable<MutationResult<IUserUpdateName_ResponseData>> {
		return this.apollo.mutate<IUserUpdateName_ResponseData, IUserUpdateName_RequestVariables>({
			mutation: UserUpdateName_Mutation,
			variables: mutationVariables,
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public updateUsername(mutationVariables: IUserUpdateUsername_RequestVariables): Observable<MutationResult<IUserUpdateUsername_ResponseData>> {
		return this.apollo.mutate<IUserUpdateUsername_ResponseData, IUserUpdateUsername_RequestVariables>({
			mutation: UserUpdateUsername_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap((data) => {
				this.notifyUser(`Follow the link sent to <${mutationVariables.username}> to verify username`, UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public requestUsernameVerification(): Observable<MutationResult<IUserRequestVerification_ResponseData>> {
		return this.apollo.mutate<IUserRequestVerification_ResponseData>({
			mutation: UserRequestVerification_Mutation,
		}).pipe(
			tap((data) => {
				this.notifyUser('Follow the link sent via email to verify username', UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public verifyUsername(mutationVariables: IUserVerifyUsername_RequestVariables): Observable<MutationResult<IUserVerifyUsername_ResponseData>> {
		return this.apollo.mutate<IUserVerifyUsername_ResponseData, IUserVerifyUsername_RequestVariables>({
			mutation: UserVerifyUsername_Mutation,
			variables: mutationVariables,
		});
	}

	public setPassword(mutationVariables: IUserSetPassword_RequestVariables): Observable<MutationResult<IUserSetPassword_ResponseData>> {
		return this.apollo.mutate<IUserSetPassword_ResponseData, IUserSetPassword_RequestVariables>({
			mutation: UserSetPassword_Mutation,
			variables: mutationVariables,
		});
	}

	public updatePassword(mutationVariables: IUserUpdatePassword_RequestVariables): Observable<MutationResult<IUserUpdatePassword_ResponseData>> {
		return this.apollo.mutate<IUserUpdatePassword_ResponseData, IUserUpdatePassword_RequestVariables>({
			mutation: UserUpdatePassword_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap((data) => {
				this.notifyUser('Password updated successfully', UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public sendResetLink(mutationVariables: IUserSendPasswordReset_RequestVariables): Observable<MutationResult<IUserSendPasswordReset_ResponseData>> {
		return this.apollo.mutate<IUserSendPasswordReset_ResponseData, IUserSendPasswordReset_RequestVariables>({
			mutation: UserSendPasswordReset_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap((data) => {
				this.notifyUser(`Follow the link sent to <${mutationVariables.emailAddress}> to reset password`, UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public addEvent(mutationVariables: IUserAddEvent_RequestVariables): Observable<MutationResult<IUserAddEvent_ResponseData>> {
		return this.apollo.mutate<IUserAddEvent_ResponseData, IUserAddEvent_RequestVariables>({
			mutation: UserAddEvent_Mutation,
			variables: mutationVariables,
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public authorizeOAuthClient(mutationVariables: IUserAuthorizeOAuthClient_RequestVariables): Observable<MutationResult<IUserAuthorizeOAuthClient_ResponseData>> {
		return this.apollo.mutate<IUserAuthorizeOAuthClient_ResponseData, IUserAuthorizeOAuthClient_RequestVariables>({
			mutation: UserAuthorizeOAuthClient_Mutation,
			variables: mutationVariables,
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public authorizeUserRegistration(mutationVariables: IUserAuthorizeRegistration_RequestVariables): Observable<MutationResult<IUserAuthorizeRegistration_ResponseData>> {
		return this.apollo.mutate<IUserAuthorizeRegistration_ResponseData, IUserAuthorizeRegistration_RequestVariables>({
			mutation: UserAuthorizeRegistration_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap((data) => {
				this.notifyUser(`Verification code sent to <${mutationVariables.username}>`, UserNotificationType.Success, undefined, 30 * 1000);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	public deleteUser(): Observable<MutationResult<IUserDelete_ResponseData>> {
		return this.apollo.mutate<IUserDelete_ResponseData>({
			mutation: UserDelete_Mutation,
		}).pipe(
			tap((data) => {
				this.notifyUser('Thank you for using TaskTrain', UserNotificationType.Success);
				void this.authenticationService.accountsClient.clearTokens();
				void this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.Register])
					.then((navigationResult) => {
						void this.gqlClientService.closeWebSocket();
						this.gqlCacheService.reinitializeCache();
					});
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return observableThrowError(() => error);
			}),
		);
	}

	private notifyUser(message: string, notificationType: UserNotificationType, summary?: string, displayMilliseconds?: number): void {
		this.messageService.publish(new UserNotificationMessage(message, notificationType, summary, displayMilliseconds));
	}
}
