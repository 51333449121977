import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../procedure-status.type';

// @ToDo: Replace with `AssignmentCreatePermission`
export class ProcedureAssignPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);

		this.isAuthorized = procedureStatus === ProcedureStatus.Published;
		this.message = procedureStatus === ProcedureStatus.Published ? 'Create new Assignment workflow' : `Only PUBLISHED ${entityType} may be assigned`;
	}
}
