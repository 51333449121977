/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult } from '@tasktrain/shared';


export const OrganizationDelete_Mutation = gql`
	mutation OrganizationDelete($accountId: ID!, $organizationId: ID!)  {
		OrganizationDelete(accountId: $accountId, organizationId: $organizationId) {
			objectId
			objectTypeName
		}
	}
`;

export const OrganizationDelete_Name = (OrganizationDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IOrganizationDelete_RequestVariables {
	accountId: string;
	organizationId: string;
}

export interface IOrganizationDelete_ResponseData {
	OrganizationDelete: DeletedObjectResult;
}
