import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtraSubscriptionOptions, SubscriptionResult } from 'apollo-angular';
import { FetchResult } from '@apollo/client/core';
import { SubscriptionOptionsAlone } from 'apollo-angular/types';

import { GQLClientSubscriptionService } from '../../services/gql/gql-client-subscription.service';
import { GQLResultTypeName } from '@tasktrain/shared';
import { AccountUpdated_Subscription, IAccountUpdated_RequestVariables, IAccountUpdated_ResponseData } from './account-updated.subscription';


@Injectable({ providedIn: 'root' })
export class AccountUpdatedService extends GQLClientSubscriptionService<IAccountUpdated_ResponseData, IAccountUpdated_RequestVariables> {
	public document = AccountUpdated_Subscription;

	public subscribe(
		variables?: IAccountUpdated_RequestVariables,
		options?: SubscriptionOptionsAlone<IAccountUpdated_RequestVariables, IAccountUpdated_ResponseData>,
		extra?: ExtraSubscriptionOptions,
	): Observable<SubscriptionResult<IAccountUpdated_ResponseData>> {
		return super.subscribe(variables, options, extra,
			({ data: { AccountUpdated } }: FetchResult<IAccountUpdated_ResponseData>) => {
				if (AccountUpdated.__typename === GQLResultTypeName.DeletedObjectResult) {
					this.apollo.client.cache.evict({ id: `${AccountUpdated.objectTypeName}:${AccountUpdated.objectId}` });
				}
			});
	}
}
