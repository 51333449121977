import { DomainEntityBase } from '../../core/domain/domain-entity-base.model';
import { ManualRole, ManualStatus, ManualType } from './manual.types';
import { Content } from '../content/content.model';
import { DomainEntityTypeName } from '../../core/domain/domain-entity-type-name.types';
import { newEntityId } from '../../core/domain/entity-id.methods';
import { IOrganization } from '../../common/organization/organization.model';
import { IAssignment } from '../../inbox/assignment/assignment.type';
import { IAccountWithManualRole } from '../../common/account/account-with-manual-role.type';
import { OmitMethodKeys } from '../../core/infrastructure/types/utility.types';
import { populate } from '../../core/infrastructure/methods/populate.method';
import { IAssignmentSummary } from '../../common/assignment-summary/assignment-summary.model';
import { IProcedure } from '../procedure/procedure.model';
import { IManualDocument } from './manual-document.type';


/** Data Transfer Object of `Manual`
 *  Methods removed & properties marked optional to create accurate GraphQL DTO of Domain Entity
 *
 *  @ToDo: consider renaming with `DTO` suffix
 */
export interface IManual extends OmitMethodKeys<Partial<Manual>> {
}

/** domain model class: A collection of 0 or more Procedures */
export class Manual extends DomainEntityBase {
	public name = '';
	public description = '';
	public status: ManualStatus = ManualStatus.Active;
	public type: ManualType = ManualType.Private;
	/** Provided by external resolver */
	public procedureList: IProcedure[] = [];
	public contentList: Content[] = [];
	public sectorList: string[] = [];
	public keywordList: string[] = [];
	public functionList: string[] = [];
	/** Provided by external resolver */
	public organization: IOrganization;
	/** Provided by external resolver */
	public assignmentSummaryList: IAssignmentSummary[] = [];
	/** Provided by external resolver */
	public assignmentList: IAssignment[] = [];
	/** Provided by external resolver */
	public accountWithManualRoleList: IAccountWithManualRole[] = [];
	public currentUserRole: ManualRole;
	public readonly __typename: DomainEntityTypeName.Manual = DomainEntityTypeName.Manual;

	public constructor(initialValues?: IManualDocument, currentUserRole?: ManualRole) {
		super();
		this._id = this._id || newEntityId();
		if (initialValues) {
			this.organization = { _id: initialValues.organizationId };
			populate<IManual>(this, initialValues);
		}
		this.currentUserRole = currentUserRole || this.currentUserRole;
	}
}
