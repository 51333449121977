/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUser } from '@tasktrain/shared';


export const UserUpdateName_Mutation = gql`
	mutation UserUpdateName($nameFirst: String, $nameLast: String){
		UserUpdateName(nameFirst: $nameFirst, nameLast: $nameLast) {
			_id
			nameFirst
			nameLast
		}
	}
`;

export const UserUpdateName_Name = (UserUpdateName_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserUpdateName_RequestVariables {
	nameFirst?: string;
	nameLast?: string;
}

export interface IUserUpdateName_ResponseData {
	UserUpdateName: IUser;
}
