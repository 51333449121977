import { startCase } from 'lodash-unified';

import { OrganizationRole } from '../organization-role.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class OrganizationUpdateDescriptorsPermission {
	public isAuthorized: boolean;
	public nameMessage: string;
	public descriptionMessage: string;
	public sectorListMessage: string;

	public constructor(organizationRole: OrganizationRole) {
		const entityType = startCase(DomainEntityTypeName.Organization);

		this.isAuthorized = organizationRole === OrganizationRole.Owner;
		this.nameMessage = this.isAuthorized ? `Rename ${entityType}` : `Organization Owner Role required to Rename ${entityType}s`;
		this.descriptionMessage = this.isAuthorized ? 'Update description' : `Organization Owner Role required to update a(n) ${entityType} description'`;
		this.sectorListMessage = this.isAuthorized ? `Add tags to categorize ${entityType} by economic sector` :
			`Organization Owner Role required to categorize ${entityType} by economic sector`;
	}
}
