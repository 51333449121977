import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from '../../core/services/message-service/message.service';
import { AccountMutationsService } from '../../core/gql-operations/account/account-mutations.service';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { AuthenticationService } from '../../core/services/authentication.service';


@Injectable({
	providedIn: 'root',
})
export class VerifyEmailRoutingGuardService {
	public constructor(
		private viewRouter: Router,
		private messageService: MessageService,
		private userMutationsService: UserMutationsService,
		private accountMutationsService: AccountMutationsService,
		private authenticationService: AuthenticationService,
	) {
	}

	public async canActivate(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean> {
		if (await this.authenticationService.isSignedIn()) {
			this.userMutationsService.signOut().subscribe();
		}
		this.accountMutationsService.updateVerification({ verificationToken: activatedView.params.token }).subscribe();
		return true;
	}
}
