import { ExchangeRoutingPath, InboxRoutingPaths, LibraryRoutingPaths } from '../../../app-routing.module';

// `undefined` property initialization leads to ApolloClient errors
export class ViewStateParameters {
	public exchangePath: ExchangeRoutingPath = null;
	public accountId = '';
	public organizationId = '';
	public manualId = '';
	public procedureId = '';
	public procedureView: LibraryRoutingPaths.Steps | LibraryRoutingPaths.ProcedureAssignments = null;
	public procedureStepId = '';
	public procedureStepIndexPath: number[] | string = '';
	public inboxPath: InboxRoutingPaths = null;
	public assignmentView: InboxRoutingPaths.Account | InboxRoutingPaths.Organization | LibraryRoutingPaths.ProcedureAssignments |
		LibraryRoutingPaths.ManualAssignments | LibraryRoutingPaths.Steps = null;
	public assignmentId = '';
	public assignmentTaskId = '';
	public contentId = '';
	public __typename = 'ViewStateParameters';
}
