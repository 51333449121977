/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserSendPasswordReset_Mutation = gql`
	mutation UserSendPasswordReset($emailAddress: String!){
		UserSendPasswordReset(emailAddress: $emailAddress)
	}
`;

export const UserSendPasswordReset_Name = (UserSendPasswordReset_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserSendPasswordReset_RequestVariables {
	emailAddress: string;
}

export interface IUserSendPasswordReset_ResponseData {
	UserSendPasswordReset: boolean;
}
