/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IProcedureStep } from '@tasktrain/shared';


export const ProcedureRead$ProcedureStepListNested_Fields = gql`
	fragment NestedProcedureStepFields on ProcedureStep {
		_id
		name
	}
`;

export const ProcedureRead$ProcedureStepList_Fields = gql`
	fragment ProcedureStepListFields on Procedure {
		procedureStepList {
			...NestedProcedureStepFields
			procedureStepList {
				...NestedProcedureStepFields
				procedureStepList {
					...NestedProcedureStepFields
					procedureStepList {
						...NestedProcedureStepFields
						procedureStepList {
							...NestedProcedureStepFields
							procedureStepList {
								...NestedProcedureStepFields
								procedureStepList {
									...NestedProcedureStepFields
								}
							}
						}
					}
				}
			}
		}
	}
	${ProcedureRead$ProcedureStepListNested_Fields}
`;

export const ProcedureRead$ProcedureStepList_Query = gql`
	query ProcedureRead_ProcedureStepList($procedureId: ID!) {
		ProcedureRead(procedureId: $procedureId) {
			_id
			name
			...ProcedureStepListFields
		}
	}
	${ProcedureRead$ProcedureStepList_Fields}
`;

export const ProcedureRead$ProcedureStepList_Name = (ProcedureRead$ProcedureStepList_Query.definitions[0] as OperationDefinitionNode).name?.value;

export interface IProcedureRead$ProcedureStepList_RequestVariables {
	procedureId: string;
}

export interface IProcedureRead$ProcedureStepList_ResponseData {
	ProcedureRead: {
		procedureStepList: IProcedureStep[];
	};
}
