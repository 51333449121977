/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUser } from '@tasktrain/shared';


export const UserRead_Fields = gql`
	fragment UserFields on User {
		_id
		nameLast
		nameFirst
		username
		isVerified
		eventList {
			name
			dateTime
		}
	}
`;

export const UserRead_Query = gql`
	query UserRead {
		UserRead {
			...UserFields
		}
	}
	${UserRead_Fields}
`;

export const UserRead_Name = (UserRead_Query.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserRead_RequestVariables {
}

export interface IUserRead_ResponseData {
	UserRead: IUser;
}
