/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IUserSignInResult } from '@tasktrain/shared';

export const UserRefreshSession_Mutation = gql`
	mutation UserRefreshSession($refreshToken: String!, $accessToken: String!){
		UserRefreshSession(refreshToken: $refreshToken, accessToken: $accessToken) {
			accessToken
			refreshToken
			sessionId
			user {
				_id
			}
		}
	}
`;

export const UserRefreshSession_Name = (UserRefreshSession_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserRefreshSession_RequestVariables {
	accessToken: string;
	refreshToken: string;
}

export interface IUserRefreshSession_ResponseData {
	UserRefreshSession: IUserSignInResult;
}
