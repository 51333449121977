import { ApolloClient, DocumentNode, InMemoryCache, NormalizedCacheObject, Resolvers } from '@apollo/client/core';
import { Injectable } from '@angular/core';

import {
	AssignmentTaskStatusCategoryClosedList,
	buildIndexPath,
	ContentType,
	DomainEntityChildListPropertyName,
	DomainEntityPropertyTypeName,
	DomainEntityTypeName,
	IAccount,
	IAssignment,
	IAssignmentProgress,
	IAssignmentTask,
	IContent,
	IDiscussionItemAttachment,
	IProcedure,
	IProcedureStep,
	MediaTypePrefix,
} from '@tasktrain/shared';

import { IUserRead_ResponseData } from '../../gql-operations/user/user-read.query';
import { FileStorageService } from '../file-storage.service';
import { UserRead$AccountList_Query } from '../../gql-operations/user/user-read-account-list.query';
import {
	IProcedureRead$ProcedureStepList_RequestVariables,
	IProcedureRead$ProcedureStepList_ResponseData,
	ProcedureRead$ProcedureStepList_Query,
} from '../../gql-operations/procedure/procedure-read-procedure-step-list.query';


/** `@Injectable()` service providing top-level GraphQL resolvers to GQLClientService */
@Injectable({
	providedIn: 'root',
})
export class GQLResolversService {
	public constructor(private fileAccessService: FileStorageService) {
	}

	public getResolvers(): Resolvers {
		return {
			[DomainEntityTypeName.ProcedureStep]: {
				siblingPrevious: this.relatedProcedureStep.bind(this),
				siblingNext: this.relatedProcedureStep.bind(this),
			},
			[DomainEntityTypeName.Content]: {
				previewURL: this.contentPreviewResolver.bind(this),
			},
			[DomainEntityTypeName.DiscussionItemAttachment]: {
				previewURL: this.attachmentPreviewResolver.bind(this),
			},
			[DomainEntityTypeName.Assignment]: {
				progress: this.assignmentProgressResolver.bind(this),
			},
			[DomainEntityTypeName.Procedure]: {
				imagePreviewURL: this.procedureImagePreviewResolver.bind(this),
			},
		};
	}

	private async relatedProcedureStep(
		parent: IProcedureStep, queryArguments: null,
		queryContext: {
			cache: InMemoryCache; client: ApolloClient<NormalizedCacheObject>;
			schemas?: DocumentNode[];
		},
		queryInfo: any,
	): Promise<IProcedureStep> {
		if (parent.procedure) {
			const { data: { ProcedureRead } } = await queryContext.client.query<IProcedureRead$ProcedureStepList_ResponseData, IProcedureRead$ProcedureStepList_RequestVariables>({
				query: ProcedureRead$ProcedureStepList_Query,
				variables: { procedureId: parent.procedure._id },
			});
			const procedureStepIndexPath = buildIndexPath(ProcedureRead, DomainEntityChildListPropertyName.Procedure, parent._id) || [];
			let parentEntity: IProcedureStep | IProcedure = ProcedureRead;
			for (const index of procedureStepIndexPath.slice(0, -1)) {
				parentEntity = parentEntity.procedureStepList[index];
			}
			let relatedEntity: IProcedureStep;
			switch (queryInfo.field.name.value) {
				case 'siblingPrevious':
					relatedEntity = parentEntity.procedureStepList[procedureStepIndexPath.pop() - 1];
					break;
				case 'siblingNext':
					relatedEntity = parentEntity.procedureStepList[procedureStepIndexPath.pop() + 1];
					break;
			}
			return relatedEntity;
		}
	}

	private async contentPreviewResolver(
		parent: IContent, queryArguments: null,
		queryContext: {
			cache: InMemoryCache; client: ApolloClient<NormalizedCacheObject>;
			schemas?: DocumentNode[];
		},
		queryInfo: any,
	): Promise<string> {
		const mediaTypeType = parent.mediaType.split('/').shift();
		return (parent.type === ContentType.Attached && !(mediaTypeType === MediaTypePrefix.APPLICATION || mediaTypeType === MediaTypePrefix.TEXT))
			? this.fileAccessService.getSignedUrl(parent.payload)
			: parent.preview;
	}

	private async attachmentPreviewResolver(
		parent: IDiscussionItemAttachment,
		queryArguments: null,
		queryContext: { cache: InMemoryCache; client: ApolloClient<NormalizedCacheObject>; schemas?: DocumentNode[] },
		queryInfo: any,
	): Promise<string> {
		const mediaTypeType = parent.mediaType.split('/').shift();
		return (mediaTypeType === MediaTypePrefix.IMAGE || mediaTypeType === MediaTypePrefix.VIDEO || mediaTypeType === MediaTypePrefix.AUDIO)
			? this.fileAccessService.getSignedUrl(parent.payload)
			: '';
	}

	private assignmentProgressResolver(
		parent: IAssignment,
		queryArguments: null,
		queryContext: { cache: InMemoryCache; client: ApolloClient<NormalizedCacheObject>; schemas?: DocumentNode[] },
		queryInfo: any,
	): IAssignmentProgress {
		const progressCounts: IAssignmentProgress = {
			openCountTotal: 0,
			closedCountTotal: 0,
			openCountCurrentAccount: 0,
			closedCountCurrentAccount: 0,
			__typename: DomainEntityPropertyTypeName.AssignmentProgress,
		};
		const currentUserAccountIdList: string[] = queryContext.cache.readQuery<IUserRead_ResponseData>({ query: UserRead$AccountList_Query })?.UserRead.accountList
			.map((account: IAccount) => {
				return account._id;
			}) || [];
		const calculateProgressCounts = (assignmentTaskList: IAssignmentTask[] = []): void => {
			assignmentTaskList.forEach((assignmentTask: IAssignmentTask) => {
				const isClosed = AssignmentTaskStatusCategoryClosedList.includes(assignmentTask.assignmentTaskStatus);
				const isAssignedToCurrentUser = currentUserAccountIdList.includes(assignmentTask.assigneeAccount?._id);
				isClosed ? progressCounts.closedCountTotal++ : progressCounts.openCountTotal++;
				if (isAssignedToCurrentUser) {
					isClosed ? progressCounts.closedCountCurrentAccount++ : progressCounts.openCountCurrentAccount++;
				}
				calculateProgressCounts(assignmentTask.assignmentTaskList);
			});
		};
		calculateProgressCounts(parent.assignmentTaskList);
		return progressCounts;
	}

	private async procedureImagePreviewResolver(
		parent: IProcedure, queryArguments: null,
		queryContext: {
			cache: InMemoryCache; client: ApolloClient<NormalizedCacheObject>;
			schemas?: DocumentNode[];
		},
		queryInfo: any,
	): Promise<string> {
		return parent.imageLocator && this.fileAccessService.getSignedUrl(parent.imageLocator);
	}
}
