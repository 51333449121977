export enum GQLSchemaRootName {
	Query = 'Query',
	Mutation = 'Mutation',
	Subscription = 'Subscription'
}

export enum GQLQueryName {
	UserRead = 'UserRead',
	AccountRead = 'AccountRead',
	OrganizationRead = 'OrganizationRead',
	ManualRead = 'ManualRead',
	ProcedureRead = 'ProcedureRead',
	ProcedureStepRead = 'ProcedureStepRead',
	ContentRead = 'ContentRead',
	AssignmentRead = 'AssignmentRead',
	AssignmentTaskRead = 'AssignmentTaskRead',
	CommentThreadRead = 'CommentThreadRead',
	CategorizationListRead = 'CategorizationListRead',
	LinkMetadataRead = 'LinkMetadataRead',
	ArticleListRead = 'ArticleListRead',
	ArticleRead = 'ArticleRead',
	BillingPlanListRead = 'BillingPlanListRead',
	BillingPlanRead = 'BillingPlanRead',
	ExchangeRead = 'ExchangeRead',
}

export enum GQLMutationName {
	UserRegister = 'UserRegister',
	UserInvitation = 'UserInvitation',
	UserSignIn = 'UserSignIn',
	UserSignOut = 'UserSignOut',
	UserRefreshSession = 'UserRefreshSession',
	UserRequestVerification = 'UserRequestVerification',
	UserSendPasswordReset = 'UserSendPasswordReset',
	UserUpdateUsername = 'UserUpdateUsername',
	UserVerifyUsername = 'UserVerifyUsername',
	UserUpdatePassword = 'UserUpdatePassword',
	UserSetPassword = 'UserSetPassword',
	UserUpdateName = 'UserUpdateName',
	UserAddEvent = 'UserAddEvent',
	UserAuthorizeOAuthClient = 'UserAuthorizeOAuthClient',
	UserAuthorizeRegistration = 'UserAuthorizeRegistration',
	UserDelete = 'UserDelete',
	AccountRequestVerification = 'AccountRequestVerification',
	AccountUpdateEmailAddress = 'AccountUpdateEmailAddress',
	AccountUpdateDisplayName = 'AccountUpdateDisplayName',
	AccountUpdateRole = 'AccountUpdateRole',
	AccountUpdateAssignmentViewStatus = 'AccountUpdateAssignmentViewStatus',
	AccountUpdateAssignmentArchiveStatus = 'AccountUpdateAssignmentArchiveStatus',
	AccountUpdateCommentThreadArchiveStatus = 'AccountUpdateCommentThreadArchiveStatus',
	AccountUpdateCommentThreadViewStatus = 'AccountUpdateCommentThreadViewStatus',
	AccountUpdateVerification = 'AccountUpdateVerification',
	AccountUpdateEmailNotificationTypeList = 'AccountUpdateEmailNotificationTypeList',
	OrganizationCreate = 'OrganizationCreate',
	OrganizationUpdateDescriptors = 'OrganizationUpdateDescriptors',
	OrganizationUpdatePaymentMethod = 'OrganizationUpdatePaymentMethod',
	OrganizationUpdateSubscription = 'OrganizationUpdateSubscription',
	OrganizationRemoveMember = 'OrganizationRemoveMember',
	OrganizationDelete = 'OrganizationDelete',
	ManualCreate = 'ManualCreate',
	ManualDelete = 'ManualDelete',
	ManualAddAccountAccess = 'ManualAddAccountAccess',
	ManualRemoveAccountAccess = 'ManualRemoveAccountAccess',
	ManualUpdateDescriptors = 'ManualUpdateDescriptors',
	ManualUpdateCategorization = 'ManualUpdateCategorization',
	ManualUpdateProcedureListOrder = 'ManualUpdateProcedureListOrder',
	ProcedureCreate = 'ProcedureCreate',
	ProcedureUpdateDescriptors = 'ProcedureUpdateDescriptors',
	ProcedureUpdateStatus = 'ProcedureUpdateStatus',
	ProcedureUpdateCopyProtection = 'ProcedureUpdateCopyProtection',
	ProcedureMoveToManual = 'ProcedureMoveToManual',
	ProcedureUpdateCategorization = 'ProcedureUpdateCategorization',
	ProcedureUpdateProcedureStepListOrder = 'ProcedureUpdateProcedureStepListOrder',
	ProcedureUpdateDefaultAssignee = 'ProcedureUpdateDefaultAssignee',
	ProcedureUpdateImage = 'ProcedureUpdateImage',
	ProcedureAddContent = 'ProcedureAddContent',
	ProcedureUpdateContentListOrder = 'ProcedureUpdateContentListOrder',
	ProcedureDelete = 'ProcedureDelete',
	ProcedureStepCreate = 'ProcedureStepCreate',
	ProcedureStepUpdateDescriptors = 'ProcedureStepUpdateDescriptors',
	ProcedureStepAddContent = 'ProcedureStepAddContent',
	ProcedureStepUpdateContentListOrder = 'ProcedureStepUpdateContentListOrder',
	ProcedureStepUpdateDefaultAssignee = 'ProcedureStepUpdateDefaultAssignee',
	ProcedureStepRemoveContent = 'ProcedureStepRemoveContent',
	ProcedureStepDelete = 'ProcedureStepDelete',
	ProcedureCopyToManual = 'ProcedureCopyToManual',
	ContentCreate = 'ContentCreate',
	ContentUpdate = 'ContentUpdate',
	AssignmentCreate = 'AssignmentCreate',
	AssignmentDelete = 'AssignmentDelete',
	AssignmentUpdateRequestor = 'AssignmentUpdateRequestor',
	AssignmentUpdateTagList = 'AssignmentUpdateTagList',
	AssignmentUpdateAssignmentTaskStatus = 'AssignmentUpdateAssignmentTaskStatus',
	AssignmentUpdateAssignmentTaskAssignee = 'AssignmentUpdateAssignmentTaskAssignee',
	AssignmentUpdateAssignmentTaskDateTime = 'AssignmentUpdateAssignmentTaskDateTime',
	AssignmentUpdateDescriptors = 'AssignmentUpdateDescriptors',
	AssignmentUpdatePriority = 'AssignmentUpdatePriority',
	CommentThreadCreateComment = 'CommentThreadCreateComment',
	CommentUpdate = 'CommentUpdate',
	CommentDelete = 'CommentDelete',
	CommentThreadAddFollower = 'CommentThreadAddFollower',
	CommentThreadRemoveFollower = 'CommentThreadRemoveFollower',
	ContentRemove = 'ContentRemove',
	ContentDelete = 'ContentDelete'
}

export enum GQLSubscriptionName {
	OrganizationUpdated = 'OrganizationUpdated',
	OrganizationUpdatedAssignmentList = 'OrganizationUpdatedAssignmentList',
	AccountUpdated = 'AccountUpdated',
	AccountUpdatedAssignmentList = 'AccountUpdatedAssignmentList',
	AccountUpdatedCommentThreadList = 'AccountUpdatedCommentThreadList',
	ManualUpdated = 'ManualUpdated',
	ManualUpdatedAccessList = 'ManualUpdatedAccessList',
	ManualUpdatedProcedureList = 'ManualUpdatedProcedureList',
	ManualUpdatedAssignmentList = 'ManualUpdatedAssignmentList',
	ProcedureUpdated = 'ProcedureUpdated',
	ProcedureUpdatedProcedureStepList = 'ProcedureUpdatedProcedureStepList',
	ProcedureUpdatedContentList = 'ProcedureUpdatedContentList',
	ProcedureUpdatedAssignmentList = 'ProcedureUpdatedAssignmentList',
	ProcedureStepUpdated = 'ProcedureStepUpdated',
	ProcedureStepUpdatedContentList = 'ProcedureStepUpdatedContentList',
	AssignmentUpdated = 'AssignmentUpdated',
	AssignmentUpdatedCommentThreadList = 'AssignmentUpdatedCommentThreadList',
	CommentThreadUpdated = 'CommentThreadUpdated'
}
