/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAssignmentWithAccountViewStatus } from '@tasktrain/shared';


export const AccountUpdateAssignmentViewStatus_Mutation = gql`
	mutation AccountUpdateAssignmentViewStatus($accountId: ID!, $assignmentId: ID!) {
		AccountUpdateAssignmentViewStatus(accountId: $accountId, assignmentId: $assignmentId) {
			_id
			viewStatus
			unreadChildIdList
		}
	}
`;

export const AccountUpdateAssignmentViewStatus_Name = (AccountUpdateAssignmentViewStatus_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateAssignmentViewStatus_RequestVariables {
	accountId: string;
	assignmentId: string;
}

export interface IAccountUpdateAssignmentViewStatus_ResponseData {
	AccountUpdateAssignmentViewStatus: IAssignmentWithAccountViewStatus;
}
