/** tests password length >6 and contains one of uppercase, lowercase, numeric, or non-alphabetic characters */
export const passwordIsComplex = (password: unknown): boolean => {
	if (typeof password === 'string') {
		const hasUpperCase: number = /[A-Z]/.test(password) ? 1 : 0;
		const hasLowerCase: number = /[a-z]/.test(password) ? 1 : 0;
		const hasNumbers: number = /\d/.test(password) ? 1 : 0;
		const hasNonAlphas: number = /\W/.test(password) ? 1 : 0;
		return password?.length > 6 && (hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphas > 1);
	} else {
		return false;
	}
};
