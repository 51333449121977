<div class="text-center ui-widget" [ngSwitch]="authorizationComplete" data-tt-tour="UserAuthorizeOAuthClient">
	<ng-container *ngSwitchCase="false">
		<h1 class="tt-start-panel-title mb-3">Authorize {{ clientName }}?</h1>
		<p class="tt-client-description">Authorizing {{ clientName }} will allow it to read your data and take supported
			actions using your TaskTrain <{{ username }}> user login even when you are not signed in.</p>
		<button
			type="button"
			data-tt-tour="UserAuthorizeOAuthClient-Button-Cancel"
			class="tt-btn-m tt-btn-cancel me-2"
			(click)="onCancelButtonPress()"
			[disabled]="waitingForServer">
			<span class="tt-icon-cancel" aria-hidden="true"></span>
			<span class="tt-btn-text">Cancel</span>
		</button>
		<button
			type="button"
			class="tt-btn-m tt-btn-create"
			data-tt-tour="UserAuthorizeOAuthClient-Button-Authorize"
			(click)="onAuthorizeButtonPress()"
			[disabled]="waitingForServer">
			<span [ngClass]="{ 'fa': true, 'fa-check': !waitingForServer, 'fa-spinner fa-spin': waitingForServer }"></span>
			<span class="tt-btn-text">Authorize</span>
		</button>
	</ng-container>
	<ng-container *ngSwitchCase="true">
		<div class="tt-success-checkmark">
			<div class="tt-icon-check">
				<span class="tt-icon-line tt-icon-line-tip"></span>
				<span class="tt-icon-line tt-icon-line-long"></span>
				<div class="tt-icon-circle"></div>
				<div class="tt-icon-fix"></div>
			</div>
		</div>
		<p class="tt-client-description">Authorization successful! Redirecting back to {{ clientName }}...</p>
	</ng-container>
</div>
