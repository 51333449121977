/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult, IAccount } from '@tasktrain/shared';

import { AccountRead_Fields } from './account-read.query';


export const AccountUpdated_Subscription = gql`
	subscription AccountUpdated($accountId: ID!){
		AccountUpdated(accountId: $accountId){
			__typename
			... on Account {
				...AccountFields
			}
			... on DeletedObjectResult {
				objectId
				objectTypeName
			}
		}
	}
	${AccountRead_Fields}
`;

export const AccountUpdated_Name = (AccountUpdated_Subscription.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdated_RequestVariables {
	accountId: string;
}

export interface IAccountUpdated_ResponseData {
	AccountUpdated: IAccount | DeletedObjectResult;
}

