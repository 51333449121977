import { IProcedure } from '../../../library/procedure/procedure.model';
import { IAssignment } from '../../../inbox/assignment/assignment.type';
import { ICommentThread } from '../../../inbox/comment-thread/comment-thread.model';
import { DomainEntityChildListPropertyName } from '../../domain/domain-entity-type-name.types';
import { DocumentChildListPropertyName } from '../../persistence/document.types';
import type { IProcedureDocument } from '../../../library/procedure/procedure-document.type';
import type { IProcedureStepDocument } from '../../../library/procedure-step/procedure-step-document.type';
import { ICommentThreadDocument } from '../../../inbox/comment-thread/comment-thread-document.type';


type ParentEntity = IAssignment | IProcedure | IProcedureDocument | ICommentThread | ICommentThreadDocument;

export const buildIndexPath = (
	parentEntity: ParentEntity,
	childListPropertyName: DomainEntityChildListPropertyName | DocumentChildListPropertyName,
	childIdToFind: string,
): number[] => {
	// generic `documentToSearch` typing prevents TS7053 error due to index access
	const buildPathRecursively = (documentToSearch: Record<string, any>, stepId: string, parentIndexPath: number[] = []): number[] => {
		if (!documentToSearch[childListPropertyName]) {
			return undefined;
		}
		const foundAtIndex = documentToSearch[childListPropertyName].findIndex((document: IProcedureStepDocument) => {
			return document._id === stepId;
		});
		if (foundAtIndex > -1) {
			return [...parentIndexPath, foundAtIndex];
		} else {
			for (let childIndex = 0; childIndex < documentToSearch[childListPropertyName].length; childIndex++) {
				const foundInChildren = buildPathRecursively(documentToSearch[childListPropertyName][childIndex] as ParentEntity, stepId, [...parentIndexPath, childIndex]);
				if (foundInChildren) {
					return foundInChildren;
				}
			}
		}
	};
	return buildPathRecursively(parentEntity, childIdToFind);
};
