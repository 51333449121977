/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAccount } from '@tasktrain/shared';


export const AccountUpdateEmailAddress_Mutation = gql`
	mutation AccountUpdateEmailAddress($accountId: ID!, $emailAddress: String!) {
		AccountUpdateEmailAddress(accountId: $accountId, emailAddress: $emailAddress) {
			_id
			emailAddress
			organizationRole
			isVerified
		}
	}
`;

export const AccountUpdateEmailAddress_Name = (AccountUpdateEmailAddress_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateEmailAddress_RequestVariables {
	accountId: string;
	emailAddress: string;
}

export interface IAccountUpdateEmailAddress_ResponseData {
	AccountUpdateEmailAddress: IAccount;
}
