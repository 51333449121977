/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { User } from '@tasktrain/shared';


export const UserUpdateUsername_Mutation = gql`
	mutation UserUpdateUsername($username: String!){
		UserUpdateUsername(username: $username) {
			_id
			username
			isVerified
		}
	}
`;

export const UserUpdateUsername_Name = (UserUpdateUsername_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserUpdateUsername_RequestVariables {
	username: string;
}

export interface IUserUpdateUsername_ResponseData {
	UserUpdateUsername: User;
}
