import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../procedure-status.type';

export class ProcedureUpdatePublishStatePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const mayUpdate: boolean = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = mayUpdate && isEditable;
		this.message = mayUpdate && (procedureStatus === ProcedureStatus.Draft) ? `Make ${entityType} available for assignment` :
			mayUpdate && (procedureStatus === ProcedureStatus.Published) ? `Make ${entityType} unavailable for assignment` :
				!mayUpdate && (procedureStatus === ProcedureStatus.Draft) ? `Manual Owner or Manager Role required to make ${entityType}s available for assignment` :
					!mayUpdate && (procedureStatus === ProcedureStatus.Published) ? `Manual Owner or Manager Role required to make ${entityType}s unavailable for assignment` :
						`${procedureStatus} ${entityType} may not have publishing state changed`;
	}
}
