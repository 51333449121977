import { ManualRole } from '../../../library/manual/manual.types';


export class AssignmentUpdateDescriptorsPermission {
	public isAuthorized: boolean;
	public nameMessage: string;
	public descriptionMessage: string;

	public constructor(manualRole: ManualRole, isAssignmentOrTaskAssignee: boolean) {
		this.isAuthorized = ((manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) || isAssignmentOrTaskAssignee);
		this.nameMessage = this.isAuthorized ? 'Update Assignment Name' :
			'Only Assignment or Task Assignee or Manual Owner or Manager may update Assignment Name';
		this.descriptionMessage = this.isAuthorized ? 'Update Assignment Description' :
			'Only Assignment or Task Assignee or Manual Owner or Manager may update Assignment Description';
	}
}
