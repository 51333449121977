<div class="container tt-wrapper-authentication" data-tt-tour="Start">
	<h1 class="d-flex align-items-center justify-content-center mb-5">
		<img src="../../../assets/images/TaskTrainLogo&Wordmark.png" class="img-fluid tt-logo" alt="TaskTrain"
				 title="TaskTrain">
	</h1>
	<router-outlet></router-outlet>
	<p-card id="startSwitchView" data-tt-tour="Start-Section-SwitchView" styleClass="mt-5 p-2" class="text-center w-100"
		*ngIf="activeRouteSegment === startRouteSegmentList.SignIn || activeRouteSegment === startRouteSegmentList.Register || activeRouteSegment === startRouteSegmentList.ForgotPassword"
		(click)="switchView()" [ngSwitch]="activeRouteSegment">
		<ng-template pTemplate="header">
		<div class="text-center">
			<ng-container *ngSwitchCase="startRouteSegmentList.SignIn">
				New to TaskTrain? &nbsp;&nbsp; <span class="linkClass">Register for free</span>
			</ng-container>
			<ng-container *ngSwitchCase="startRouteSegmentList.Register">
				Already have an account? &nbsp;&nbsp; <span class="linkClass">Sign in</span>
			</ng-container>
			<ng-container *ngSwitchCase="startRouteSegmentList.ForgotPassword">
				Back to <span class="linkClass">Sign in</span>
			</ng-container>
		</div>
		</ng-template>
	</p-card>
</div>
