import { OrganizationRole } from '../organization-role.type';

export class OrganizationUpdatePaymentMethodPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(organizationRole: OrganizationRole) {
		this.isAuthorized = organizationRole === OrganizationRole.Owner;
		this.message = this.isAuthorized ? 'Update Payment Source' : 'Organization Owner Role required to update payment source';
	}
}
