/* eslint-disable no-redeclare */
import { isRefType, Ref } from '@typegoose/typegoose';
import mongoose, { InferId, RefType } from 'mongoose';

type DomainEntityOrDocument = { _id?: RefType } & Record<string, any>;

/**
 * Discriminates between Mongoose Reference and populated document and returns document identifier
 * Overload signatures provide correct return type typing when IdType is not specified
 */
export function idFromDomainEntityOrDocumentReference(entityOrDocumentReference: string | DomainEntityOrDocument): string;
export function idFromDomainEntityOrDocumentReference<IdType extends RefType>(entityOrDocumentReference: IdType | DomainEntityOrDocument, rawIdType: IdType): IdType;
export function idFromDomainEntityOrDocumentReference<IdType extends RefType = string>(
	entityOrDocumentReference: IdType | DomainEntityOrDocument,
	idType: IdType = 'string' as IdType,
) {
	return (typeof entityOrDocumentReference === idType ? entityOrDocumentReference : (entityOrDocumentReference as DomainEntityOrDocument)._id) as IdType;
}

type AllowedRefTypes = typeof String | typeof Number | typeof Buffer | typeof mongoose.Types.ObjectId | typeof mongoose.Types.Buffer;

/**
 * Discriminates between Mongoose Reference and populated document and returns document identifier
 */
export const idFromDocumentReference = <DocumentType extends { _id: InferId<DocumentType> }>(
	documentOrReference: Ref<DocumentType>,
	rawIdType: AllowedRefTypes = String,
) => {
	return isRefType(documentOrReference, rawIdType) ? documentOrReference : (documentOrReference)?._id;
};

