import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../procedure-status.type';

export class ProcedureUpdateCopyProtectionPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const mayUpdate: boolean = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = mayUpdate && isEditable;
		this.message = mayUpdate && isEditable ? 'Update copy protection' :
			!mayUpdate && isEditable ? `Manual Owner or Manager Role required to update ${entityType} copy protection` :
				`${procedureStatus} ${entityType} may not have copy protection changed.`;
	}
}
