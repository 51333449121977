/** encapsulation class for action messages sent to the MessageService message bus to user toast notifications */
export class UserNotificationMessage {
	public constructor(
		public detail: string,
		public notificationType: UserNotificationType = UserNotificationType.Info,
		public summary?: string,
		public displayMilliseconds?: number,
	) {
	}
}

export enum UserNotificationType { Success = 'success', Info = 'info', Warn = 'warn', Error = 'error' }
