import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { AuthenticationService } from '../../core/services/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class UserAuthorizeOAuthClientRoutingGuardService {
	public constructor(private viewRouter: Router, private authenticationService: AuthenticationService) {
	}

	public canActivate(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return this.authorizeOrRedirect(activatedView, viewState);
	}

	private async authorizeOrRedirect(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean | UrlTree> {
		const isSignedIn = await this.authenticationService.isSignedIn();
		if (!isSignedIn) {
			const requestedURLTree = this.viewRouter.parseUrl(`${AppRoutingPaths.Auth}/${AuthRoutingPaths.SignIn}?returnUrl=${viewState.url}`);
			const queryParametersString = Object.entries(activatedView.params) ? viewState.url.split('?')[1] : ''; // `parseUrl` strips query parameters
			requestedURLTree.queryParams.returnUrl = `${requestedURLTree.queryParams.returnUrl as string}?${queryParametersString}`;
			return requestedURLTree;
		} else {
			return true;
		}
	}
}
