import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { ProcedureStatus } from '../procedure-status.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureAddContentPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Content);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) && isEditable;
		this.message = this.isAuthorized ? `Create ${entityType}` :
			isEditable ? `Manual Owner or Manager Role required to create ${entityType}` :
				`${procedureStatus} Procedures may not have ${entityType} added.`;
	}
}
