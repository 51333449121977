import { RouterStateSnapshot } from '@angular/router';

import { populate } from '@tasktrain/shared';

import { ViewStateParameters } from './view-state-parameters.model';
import { ExchangeRoutingPath, InboxRoutingPaths, LibraryRoutingPaths, MainRoutingPaths } from '../../../app-routing.module';


/** Descend Angular `RouterStateSnapshot` hierarchy & collect matching parameters on `ViewStateParameters` object */
export const getViewStateParameters = (routerStateSnapShot: RouterStateSnapshot): ViewStateParameters => {
	let activatedRouteSnapshot = routerStateSnapShot.root;
	const viewStateParameters = new ViewStateParameters();
	viewStateParameters.inboxPath = routerStateSnapShot.url.split(`/${MainRoutingPaths.Inbox}/`).pop().split('/')[0].split('?')[0] as InboxRoutingPaths;
	viewStateParameters.inboxPath = viewStateParameters.inboxPath.replace(/[()]/g, '') as InboxRoutingPaths;
	viewStateParameters.procedureView = routerStateSnapShot.url.includes(LibraryRoutingPaths.Steps) ? LibraryRoutingPaths.Steps : null;
	viewStateParameters.exchangePath = routerStateSnapShot.url.split(`/${MainRoutingPaths.Exchange}/`).pop().split('/')[0].split('?')[0] as ExchangeRoutingPath;
	viewStateParameters.exchangePath = viewStateParameters.exchangePath.replace(/[()]/g, '') as ExchangeRoutingPath;
	do {
		populate(viewStateParameters, activatedRouteSnapshot.params);
		activatedRouteSnapshot = activatedRouteSnapshot.firstChild;
	} while (activatedRouteSnapshot);
	if (typeof viewStateParameters.procedureStepIndexPath === 'string') {
		viewStateParameters.procedureStepIndexPath = viewStateParameters.procedureStepIndexPath.split(',')
			.map((numberAsString: string) => {
				return +numberAsString;
			}); // coerce to numeric array
	}
	return viewStateParameters;
};
