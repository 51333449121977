<div class="text-center" data-tt-tour="UserSetPassword">
	<h1 class="tt-start-panel-title mb-3">{{ title }}</h1>
	<form id="updatePasswordForm" [formGroup]="updatePasswordForm" novalidate name="updatePasswordForm">
		<fieldset [disabled]="waitingForServer">
			<p-inputGroup id="password" data-tt-tour="UserSetPassword-Input-Password">
				<p-inputGroupAddon>
					<span class="tt-icon-password fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="passwordInput" class="d-none">Password</label>
				<input
					formControlName="password"
					id="passwordInput"
					name="password"
					type="password"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid' : password.dirty && password.invalid }"
					autocomplete="password"
					placeholder="Password"/>
			</p-inputGroup>
			<p-message
				*ngIf="password.dirty && password.invalid"
				severity="error"
				[text]="password.errors.minlength ? 'Password must be 11 characters or longer' :
				password.errors.maxlength ? 'Password must be 222 characters or shorter' :
					password.errors.passwordComplexity ? password.errors.passwordComplexity : ''"
				class="w-100">
			</p-message>
			<p-inputGroup id="passwordConfirm" class="mt-3" data-tt-tour="UserSetPassword-Input-PasswordConfirm">
				<p-inputGroupAddon>
					<span class="tt-icon-password fa-fw" aria-hidden="true"></span>
				</p-inputGroupAddon>
				<label for="passwordConfirmInput" class="d-none">Confirm Password</label>
				<input
					formControlName="passwordConfirm"
					id="passwordConfirmInput"
					name="passwordConfirm"
					type="password"
					pInputText
					class="w-100"
					[ngClass]="{ 'is-invalid' : passwordConfirm.dirty && passwordConfirm.invalid }"
					autocomplete="password"
					placeholder="Confirm Password"/>
			</p-inputGroup>
			<p-message
				*ngIf="passwordConfirm.dirty && passwordConfirm.invalid"
				severity="error"
				[text]="passwordConfirm.errors.passwordConfirm"
				styleClass="w-100">
			</p-message>
			<button
				id="update-password-button"
				data-tt-tour="UserSetPassword-Button-UpdatePassword"
				class="mt-3 tt-btn-m tt-btn-create w-100"
				value="Reset"
				(click)="onSetPasswordButtonPress()"
				[disabled]="updatePasswordForm.invalid">
				<span [ngClass]="{'fa fa-spinner fa-spin me-1': waitingForServer}" aria-hidden="true"></span>
				{{ title }}
			</button>
		</fieldset>
	</form>
</div>
