/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


import { ICommentThreadWithAccountViewStatus } from '@tasktrain/shared';

export const AccountUpdateCommentThreadViewStatus_Mutation = gql`
	mutation AccountUpdateCommentThreadViewStatus($accountId: ID!, $commentThreadId: ID!) {
		AccountUpdateCommentThreadViewStatus(accountId: $accountId, commentThreadId: $commentThreadId) {
			_id
			viewStatus
			unreadChildIdList
		}
	}
`;

export const AccountUpdateCommentThreadViewStatus_Name = (AccountUpdateCommentThreadViewStatus_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateCommentThreadViewStatus_RequestVariables {
	accountId: string;
	commentThreadId: string;
}

export interface IAccountUpdateCommentThreadViewStatus_ResponseData {
	AccountUpdateCommentThreadViewStatus: ICommentThreadWithAccountViewStatus;
}
