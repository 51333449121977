import { GraphQLError } from 'graphql';


export const getGQLErrorMessage = (error: any): string => {
	let errorMessage: string;
	switch (true) {
		case !!error.message:
			errorMessage = error.message;
			break;
		case !!error.networkError:
			errorMessage = `${error.networkError.message} [${error.networkError.name}]`;
			break;
		case !!error.graphQLErrors.length:
			errorMessage = error.graphQLErrors.map((graphQLError: GraphQLError) => {
				return graphQLError.message;
			}).join('\n');
			break;
		default:
			errorMessage = 'An unknown error occurred.';
			break;
	}
	return errorMessage;
};
