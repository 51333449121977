import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IMessage } from './message.type';


/** simple class-based publish/subscribe message bus to provide decoupled communication of events, actions, commands */
@Injectable({
	providedIn: 'root',
})
export class MessageService {
	private message = new Subject<IMessage<any>>();

	public publish<T>(messageInstance: T): void {	// Flux/Redux: 'dispatch'
		this.message.next({ channel: messageInstance.constructor, content: messageInstance }); // Redux: {'type': string, 'payload': any }
	}

	public subscribe<T>(messageClass: Type<T>): Observable<T> {
		const channel: Type<T> = messageClass;
		return this.message.pipe(
			filter((message: IMessage<T>) => {
				return message.channel === channel;
			}),
			map((message: IMessage<T>) => {
				return message.content;
			}),
		);
	}
}
