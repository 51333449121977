import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class CommentUpdatePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(isAuthor: boolean) {
		const entityType: string = startCase(DomainEntityTypeName.DiscussionItem);

		this.isAuthorized = isAuthor;
		this.message = isAuthor ? `Edit ${entityType}` : `Only Author may edit ${entityType}`;
	}
}
