/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';

import { ViewStateParameters } from '../../services/view-state/view-state-parameters.model';


export const ViewStateParametersRead_Query = gql`
	query viewStateParametersRead {
		ViewStateParametersRead @client {
			exchangePath
			accountId
			organizationId
			manualId
			procedureId
			procedureView
			procedureStepId
			procedureStepIndexPath
			inboxPath
			assignmentView
			assignmentId
			assignmentTaskId
			contentId
		}
	}
`;

export interface IViewStateParametersRead_ResponseData {
	ViewStateParametersRead: ViewStateParameters;
}

