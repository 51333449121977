import { startCase } from 'lodash-unified';

import { OrganizationRole } from '../organization-role.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { IAccount } from '../../account/account.model';

export class OrganizationRemoveMemberPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(accountIdToRemove: string, requestorAccountId: string, organizationAccountList: IAccount[]) {
		const entityType = startCase(DomainEntityTypeName.Organization);
		const accountToRemove = organizationAccountList.find((account: IAccount) => {
			return account._id === accountIdToRemove;
		});
		const requestorAccount = organizationAccountList.find((account: IAccount) => {
			return account._id === requestorAccountId;
		});
		const requestorIsOwner = requestorAccountId === accountIdToRemove;
		const requestorIsAuthorized = (requestorAccount?._id === accountToRemove?._id) ||
			(requestorAccount?.organizationRole === OrganizationRole.Owner && requestorAccount?.isVerified);
		const isRemainingVerifiedOwner = accountToRemove?.organizationRole !== OrganizationRole.Owner || organizationAccountList.some((account: IAccount) => {
			return account._id !== accountIdToRemove && account.organizationRole === OrganizationRole.Owner && account.isVerified;
		});
		this.isAuthorized = requestorIsAuthorized && isRemainingVerifiedOwner;

		switch (true) {
			case this.isAuthorized && requestorIsOwner:
				this.message = `Leave this ${entityType}. (Your TaskTrain login & any other ${entityType} memberships remain active.)`;
				break;
			case this.isAuthorized:
				this.message = `Remove Member access to ${entityType}`;
				break;
			case !requestorIsAuthorized:
				this.message = `Only an ${entityType} Owner with a verified e-mail address remove Members.`;
				break;
			default:
				this.message = `An ${entityType} must have at least 1 Owner with a verified ${DomainEntityTypeName.Account}. Add an Owner or delete the ${entityType}.`;
				break;
		}
	}
}
