import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureCopyToManualPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(sourceIsExchangeManual: boolean, sourceManualRole: ManualRole, targetManualRole: ManualRole, isCopyProtected: boolean) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);

		this.isAuthorized = sourceIsExchangeManual || ((sourceManualRole in ManualRole) && (targetManualRole in ManualRole) && !isCopyProtected);
		this.message = !isCopyProtected && this.isAuthorized ? 'Copy to another Manual' : isCopyProtected ? `Copy protected ${entityType} may not be copied`
			: `Manual Role required to copy a ${entityType}`;
	}
}
