import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class AccountUpdateAssignmentArchiveStatusPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(isAccountOwner: boolean) {
		const entityType: string = startCase(DomainEntityTypeName.Assignment);

		this.isAuthorized = isAccountOwner;
		this.message = isAccountOwner ? `Change ${entityType} Archive Status` : `Only Account Owner may update ${entityType} Archive Status`;
	}
}
