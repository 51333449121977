import { getRecursiveChild } from '../../core/infrastructure/methods/get-recursive-child';
import { IAssignmentTask } from '../assignment-task/assignment-task.type';
import { DomainEntityChildListPropertyName } from '../../core/domain/domain-entity-type-name.types';
import { IAssignment } from './assignment.type';


export const isAssignmentOrTaskAssignee = (assignment: IAssignment, accountIdList: string[]): boolean => {
	return accountIdList.includes(assignment.assigneeAccount?._id) ||
		!!getRecursiveChild<IAssignment, IAssignmentTask>(
			assignment,
			DomainEntityChildListPropertyName.Assignment,
			(assignmentTask) => {
				return accountIdList.includes(assignmentTask.assigneeAccount?._id);
			},
		);
};
