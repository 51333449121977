import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { OrganizationRole } from '../../../common/organization/organization-role.type';

export class ManualRemoveAccountAccessPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(requestorManualRole: ManualRole, requestorOrganizationRole: OrganizationRole, manualHasRemainingOwner: boolean) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);
		this.isAuthorized = manualHasRemainingOwner && (requestorManualRole === ManualRole.Owner || requestorOrganizationRole === OrganizationRole.Owner);
		switch (true) {
			case this.isAuthorized:
				this.message = `Remove Member access to ${entityType}`;
				break;
			case manualHasRemainingOwner:
				this.message = `Only ${entityType} ${ManualRole.Owner} or ${DomainEntityTypeName.Organization} ${OrganizationRole.Owner} may remove Member access.`;
				break;
			default:
				this.message = `A ${entityType} must have at least 1 ${ManualRole.Owner}. Add an Owner or delete the ${entityType}.`;
				break;
		}
	}
}
