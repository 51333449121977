import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { ProcedureStatus } from '../procedure-status.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureArchivePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		this.message = this.isAuthorized && isEditable ? `Archive ${entityType}` :
			!this.isAuthorized && isEditable ? `Manual Owner or Manager Role required to Archive a ${entityType}` :
				this.isAuthorized && !isEditable ? `Unarchive ${entityType}` :
					!this.isAuthorized && !isEditable ? `Manual Owner or Manager Role required to Unarchive a ${entityType}` :
						`${entityType} is Archived`;
	}
}
