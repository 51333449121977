/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult } from '@tasktrain/shared';


export const ProcedureDelete_Mutation = gql`
	mutation ProcedureDelete($accountId: ID!, $procedureId: ID!)  {
		ProcedureDelete(accountId: $accountId, procedureId: $procedureId) {
			objectTypeName
			objectId
		}
	}
`;

export const ProcedureDelete_Name = (ProcedureDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IProcedureDelete_RequestVariables {
	accountId: string;
	procedureId: string;
}

export interface IProcedureDelete_ResponseData {
	ProcedureDelete: DeletedObjectResult;
}
