import { startCase } from 'lodash-unified';

import { OrganizationRole } from '../../../common/organization/organization-role.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ManualCreatePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(organizationRole: OrganizationRole) {
		const entityType = startCase(DomainEntityTypeName.Manual);

		this.isAuthorized = (organizationRole === OrganizationRole.Owner || organizationRole === OrganizationRole.Manager);
		this.message = this.isAuthorized ? `Create ${entityType}` : `Organization Owner or Manager Role required to Create a ${entityType}`;
	}
}

