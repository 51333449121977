import { NgModule } from '@angular/core';

import { StartComponent } from './start/start.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { UserSignInComponent } from './user-sign-in/user-sign-in.component';
import { UserRequestPasswordResetComponent } from './user-request-password-reset/user-request-password-reset.component';
import { AuthRoutingModule } from './_routing/auth-routing.module';
import { UserSetPasswordComponent } from './user-set-password/user-set-password.component';
import { UserAuthorizeOAuthClientComponent } from './user-authorize-oauth-client/user-authorize-oauth-client.component';
import { SharedEagerModule } from '../shared-eager/shared-eager.module';
import { MessageModule } from 'primeng/message';
import { TippyDirective } from '@ngneat/helipopper';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';

@NgModule({
	// Providers: Services and Values available to the Angular dependency injector
	providers: [],
	// Declarations: Components, Pipes, Directives belonging to this module & thereby available to others in this module
	declarations: [
		StartComponent,
		UserRegisterComponent,
		UserSignInComponent,
		UserRequestPasswordResetComponent,
		UserSetPasswordComponent,
		UserAuthorizeOAuthClientComponent,
	],
	// Imports: Other Modules whose Declarations should be available to components in this module
	imports: [AuthRoutingModule, SharedEagerModule, MessageModule, TippyDirective, InputGroupModule, InputGroupAddonModule, InputTextModule, CardModule],
	// Exports: Components, Pipes, Directives to make available to components declared in Modules importing this module
	exports: [],
	// Elements and properties other than components or directives
	schemas: [],
})
/** Angular Feature Module for User Authentication & Authorization (eagerly loaded) */
export class AuthModule {
}
