/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const AccountRequestVerification_Mutation = gql`
	mutation AccountRequestVerification($accountId: ID!) {
		AccountRequestVerification(accountId: $accountId)
	}
`;

export const AccountRequestVerification_Name = (AccountRequestVerification_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountRequestVerification_ResponseData {
	AccountRequestVerification: boolean;
}

export interface IAccountRequestVerification_RequestVariables {
	accountId: string;
}
