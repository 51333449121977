import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { OrganizationRole } from '../../organization/organization-role.type';
import { IAccount } from '../account.model';


export class AccountUpdateRolePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(accountIdToUpdate: string, requestorAccountId: string, organizationAccountList: IAccount[]) {
		const entityType = startCase(DomainEntityTypeName.Organization);
		const accountToUpdate = organizationAccountList.find((account: IAccount) => {
			return account._id === accountIdToUpdate;
		});
		const requestorAccount = organizationAccountList.find((account: IAccount) => {
			return account._id === requestorAccountId;
		});
		const requestorIsAuthorized = (requestorAccount?.organizationRole === OrganizationRole.Owner && requestorAccount?.isVerified);
		const isRemainingVerifiedOrganizationOwner = accountToUpdate?.organizationRole !== OrganizationRole.Owner ||
			organizationAccountList.some((account: IAccount) => {
				return account._id !== accountIdToUpdate && account.organizationRole === OrganizationRole.Owner && account.isVerified;
			});

		this.isAuthorized = requestorIsAuthorized && isRemainingVerifiedOrganizationOwner;
		switch (true) {
			case this.isAuthorized:
				this.message = `Change Member's ${entityType} access level`;
				break;
			case !requestorIsAuthorized:
				this.message = `Only ${entityType} Owners with verified e-mail addresses may change Member's access level.`;
				break;
			default:
				this.message = `An ${entityType} must have at least 1 Owner with a verified ${DomainEntityTypeName.Account}.`;
				break;
		}
	}
}

