/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';
import { DeletedObjectResult } from '@tasktrain/shared';


export const CommentDelete_Mutation = gql`
	mutation CommentDelete($accountId: ID!, $commentThreadId: ID!, $commentId: ID!) {
		CommentDelete(accountId: $accountId, commentThreadId: $commentThreadId, commentId: $commentId) {
			objectId
			objectTypeName
		}
	}
`;

export const CommentDelete_Name = (CommentDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface ICommentDelete_RequestVariables {
	accountId: string;
	commentThreadId: string;
	commentId: string;
}

export interface ICommentDelete_ResponseData {
	CommentDelete: DeletedObjectResult;
}
