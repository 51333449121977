import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FetchResult } from '@apollo/client/core';

import { populate, RegExValidateName } from '@tasktrain/shared';

import { IUserCredentials } from '../_routing/user-credentials-resolver.service';
import { validateFormControlEmail } from '../../core/methods/form-validation/validate-form-control-email.method';
import { validateFormControlPasswordComplexity } from '../../core/methods/form-validation/validate-form-control-password-complexity.method';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { IUserAuthorizeRegistration_ResponseData } from '../../core/gql-operations/user/user-authorize-registration.mutation';


@UntilDestroy()
@Component({
	selector: 'tt-user-register',
	templateUrl: 'user-register.component.html',
	styleUrls: ['user-register.component.scss'],
})
export class UserRegisterComponent implements OnInit {
	public waitingForServer = false;
	public showRegistrationUI = false;
	public nameFirst = new FormControl<string>(undefined, [Validators.required, Validators.pattern(RegExValidateName), Validators.minLength(1), Validators.maxLength(55)]);
	public nameLast = new FormControl<string>(undefined, [Validators.required, Validators.pattern(RegExValidateName), Validators.minLength(1), Validators.maxLength(55)]);
	public organizationName = new FormControl<string>(undefined, [Validators.maxLength(111)]);
	public username = new FormControl<string>(undefined, [validateFormControlEmail]);
	public password = new FormControl<string>(undefined, [Validators.minLength(11), Validators.maxLength(222), validateFormControlPasswordComplexity]);
	public passwordConfirm = new FormControl<string>(undefined, [Validators.required, this.passwordConfirmValidation.bind(this)]);
	public verificationCode = new FormControl<string>(undefined, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
	public registerForm = new FormGroup({
		nameFirst: this.nameFirst,
		nameLast: this.nameLast,
		organizationName: this.organizationName,
		username: this.username,
		password: this.password,
		passwordConfirm: this.passwordConfirm,
		verificationCode: this.verificationCode,
	},
	{ updateOn: 'blur' },
	);
	public requestEmailVerificationForm = new FormGroup({ username: this.username }, { updateOn: 'blur' });
	private userCredentials: IUserCredentials;

	public constructor(
		private activatedRoute: ActivatedRoute,
		private viewRouter: Router,
		private userMutationsService: UserMutationsService,
	) {
	}

	public ngOnInit(): void {
		// Set reference to UserCredentials object in ActivatedRoute data & initialize forms with values
		this.activatedRoute.parent.data
			.subscribe(({ userCredentials }: { userCredentials?: IUserCredentials }) => {
				this.userCredentials = userCredentials;
				Object.keys(this.registerForm.controls).forEach((formControlKey) => {
					this.registerForm.patchValue({ [formControlKey]: userCredentials[formControlKey] });
				});
				Object.keys(this.requestEmailVerificationForm.controls).forEach((formControlKey) => {
					this.requestEmailVerificationForm.patchValue({ [formControlKey]: userCredentials[formControlKey] });
				});
			});

		// Keep UserCredentials object in ActivatedRoute data synchronized to form values
		this.registerForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
			next: (newUserCredentials): void => {
				populate(this.userCredentials, newUserCredentials);
				Object.keys(newUserCredentials).forEach((formControlKey) => {
					this.requestEmailVerificationForm.patchValue(
						{ [formControlKey]: newUserCredentials[formControlKey as keyof typeof newUserCredentials] },
						{ emitEvent: false });
				});
			},
		});
		this.requestEmailVerificationForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
			next: (newUserCredentials): void => {
				populate(this.userCredentials, newUserCredentials);
				Object.keys(newUserCredentials).forEach((formControlKey) => {
					this.registerForm.patchValue(
						{ [formControlKey]: newUserCredentials[formControlKey as keyof typeof newUserCredentials] }, // typing prevents TS7053 error due to index access
						{ emitEvent: false });
				});
			},
		});
	}

	public passwordValidationMessage(): string {
		let message = '';
		message = this.password.errors?.minlength
			? 'Password must be 11 characters or longer'
			: this.password.errors?.maxlength
				? 'Password must be 222 characters or shorter'
				: this.password.errors?.passwordComplexity ? 'Password must contain at least 2 of: uppercase, lowercase, numeric, or non-alphabetic characters' : '';
		return message;
	}

	public onVerifyButtonPress(): void {
		if (this.requestEmailVerificationForm.valid && !this.waitingForServer) {
			this.waitingForServer = true;
			this.userMutationsService.authorizeUserRegistration({
				username: this.username.value,
			}).subscribe({
				next: ({ data }: FetchResult<IUserAuthorizeRegistration_ResponseData>): void => {
					this.showRegistrationUI = true;
				},
				error: (error: unknown): void => {
					this.waitingForServer = false;
				},
				complete: (): void => {
					this.waitingForServer = false;
				},
			});
		}
	}

	public onRegisterButtonPress(): void {
		if (this.registerForm.valid && !this.waitingForServer) {
			this.waitingForServer = true;
			const billingPlanKey = this.activatedRoute.snapshot.queryParamMap.get('billingPlanKey') || this.activatedRoute.snapshot.queryParamMap.get('billingPlanId');
			const visitorId = this.activatedRoute.snapshot.queryParamMap.get('LD_T');
			const referralCode = this.activatedRoute.snapshot.queryParamMap.get('LD_U')?.split('afmc=')[1];
			this.userMutationsService.register({
				email: this.username.value,
				password: this.password.value,
				nameFirst: this.nameFirst.value,
				nameLast: this.nameLast.value,
				verificationCode: this.verificationCode.value.toString(),
				...(billingPlanKey && { billingPlankey: billingPlanKey }),
				...(this.organizationName.value && { organizationName: this.organizationName.value }),
				...(visitorId && { visitorId: visitorId }),
				...(referralCode && { referralCode: referralCode }),
			}).subscribe({
				next: async () => {
					const redirectSuccess = await this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.SignIn]);
					if (!redirectSuccess) { // try again, as routing intermittently inexplicably fails
						void this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.SignIn]);
					}
				},
				error: (error: unknown) => {
					this.waitingForServer = false;
				},
				complete: (): void => {
					this.waitingForServer = false;
				},
			});
		}
	}

	private passwordConfirmValidation(formControl: FormControl): ValidationErrors {
		return formControl.value === this.password.value ? null : { passwordConfirmValidation: false };
	}
}
