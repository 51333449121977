import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { UserNotificationMessage, UserNotificationType } from '../../core/services/message-service/user-notification-message.model';
import { MessageService } from '../../core/services/message-service/message.service';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { AuthenticationService } from '../../core/services/authentication.service';


@Injectable({
	providedIn: 'root',
})
export class VerifyUsernameRoutingGuardService {
	public constructor(
		private viewRouter: Router,
		private messageService: MessageService,
		private userMutationsService: UserMutationsService,
		private authenticationService: AuthenticationService,
	) {
	}

	public async canActivate(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean> {
		if (await this.authenticationService.isSignedIn()) {
			this.userMutationsService.signOut().subscribe();
		}
		this.verifyUsername(activatedView);
		return true;
	}

	private verifyUsername(activatedView: ActivatedRouteSnapshot): void {
		this.userMutationsService.verifyUsername({ verificationToken: activatedView.params.token }).subscribe({
			next: ({ data }): void => {
				this.messageService.publish(new UserNotificationMessage('Username verified. Thank you!', UserNotificationType.Success));
				void this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.SignIn]);
			},
			error: (error: unknown): void => {
				this.messageService.publish(new UserNotificationMessage('Unable to verify username. Verification token already used or invalid.', UserNotificationType.Error));
				void this.viewRouter.navigate([AppRoutingPaths.Main]);
			},
		});
	}
}


