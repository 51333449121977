import { ManualRole } from '../../../library/manual/manual.types';


export class AssignmentUpdateAssignmentTaskStatusPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, isAssignmentOrTaskAssignee: boolean, organizationHasRemainingOpenAssignments: boolean = true) {
		this.isAuthorized = (!!organizationHasRemainingOpenAssignments && ((manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) || isAssignmentOrTaskAssignee));
		this.message = this.isAuthorized ? 'Update Assignment/Task Status' : !organizationHasRemainingOpenAssignments ? 'Maximum open Assignment count reached. Close Assignments or upgrade your plan to create new Assignments' : 'Only Assignment/Task Assignee or Manual Owner or Manager may update Assignment/Task Status';
	}
}
