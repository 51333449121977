import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


// `extends` to prevent error TS7053 when `"suppressImplicitAnyIndexErrors": false`, since properties are references by index
export interface IUserCredentials extends Record<string, string> {
	username?: string;
	password?: string;
	passwordConfirm?: string;
	nameFirst?: string;
	nameLast?: string;
	organizationName?: string;
}

@Injectable({
	providedIn: 'root',
})
export class UserCredentialsResolverService {
	public resolve(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): IUserCredentials {
		return {
			username: '',
			password: '',
			passwordConfirm: '',
			nameFirst: '',
			nameLast: '',
			organizationName: '',
		};
	}
}
