/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult } from '@tasktrain/shared';


export const ManualDelete_Mutation = gql`
	mutation ManualDelete($accountId: ID!, $manualId: ID!)  {
		ManualDelete(accountId: $accountId, manualId: $manualId) {
			objectTypeName
			objectId
		}
	}
`;

export const ManualDelete_Name = (ManualDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IManualDelete_RequestVariables {
	accountId: string;
	manualId: string;
}

export interface IManualDelete_ResponseData {
	ManualDelete: DeletedObjectResult;
}
