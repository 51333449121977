/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAccount } from '@tasktrain/shared';

export const AccountUpdateVerification_Mutation = gql`
	mutation AccountUpdateVerification($verificationToken: String!) {
		AccountUpdateVerification(verificationToken: $verificationToken) {
			_id
			isVerified
		}
	}
`;

export const AccountUpdateVerification_Name = (AccountUpdateVerification_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateVerification_RequestVariables {
	verificationToken: string;
}

export interface IAccountUpdateVerification_ResponseData {
	AccountUpdateVerification: IAccount;
}
