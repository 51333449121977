<p-confirmDialog [closable]="false" #defaultTemplate
								 data-tt-tour="ConfirmationDialog">
	<p-footer data-tt-tour="ConfirmationDialog-Footer">
		<button type="button" [ngClass]="['me-2', buttonStyleClasses.rejectStyles.button]"
						data-tt-tour="ConfirmationDialog-Button-Reject"
						(click)="defaultTemplate.reject()" autofocus *ngIf="defaultTemplate.rejectVisible">
			<span [class]="buttonStyleClasses.rejectStyles.icon" aria-hidden="true"></span>
			<span class="tt-btn-text">{{ defaultTemplate.rejectButtonLabel }}</span>
		</button>
		<button type="button" [ngClass]="[buttonStyleClasses.acceptStyles.button]"
						data-tt-tour="ConfirmationDialog-Button-Accept"
						(click)="defaultTemplate.accept()" *ngIf="defaultTemplate.acceptVisible">
			<span [class]="buttonStyleClasses.acceptStyles.icon" aria-hidden="true"></span>
			<span class="tt-btn-text">{{ defaultTemplate.acceptButtonLabel }}</span>
		</button>
	</p-footer>
</p-confirmDialog>
