/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { User } from '@tasktrain/shared';


export const UserRegister_Mutation = gql`
	mutation UserRegister(
		$email: String!,
		$password: String!,
		$nameFirst: String!,
		$nameLast: String!,
		$organizationName: String,
		$billingPlanKey: String,
		$referralCode: String,
		$visitorId: String,
		$verificationCode: String!
	) {
		UserRegister(
			email: $email,
			password: $password,
			nameFirst: $nameFirst,
			nameLast: $nameLast,
			organizationName: $organizationName,
			billingPlanKey: $billingPlanKey,
			referralCode: $referralCode,
			visitorId: $visitorId,
			verificationCode: $verificationCode
		) {
			_id
		}
	}
`;

export const UserRegister_Name = (UserRegister_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserRegister_RequestVariables {
	email: string;
	password: string;
	nameFirst: string;
	nameLast: string;
	organizationName?: string;
	billingPlanKey?: string;
	verificationCode: string;
}

export interface IUserRegister_ResponseData {
	UserRegister: User;
}
