import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { ProcedureStatus } from '../../procedure/procedure-status.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureStepCreatePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityChildType: string = startCase(DomainEntityTypeName.ProcedureStep);
		const isEditable: boolean = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) && isEditable;
		this.message = this.isAuthorized ? `Create ${entityChildType}` :
			isEditable ? `Manual Owner or Manager Role required to Create a ${entityChildType}` :
				`${procedureStatus} Procedures may not have Procedure Steps added.`;
	}
}
