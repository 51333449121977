import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { OrganizationRole } from '../../../common/organization/organization-role.type';

export class ManualAddAccountAccessPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, organizationRole: OrganizationRole) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);

		this.isAuthorized = manualRole === ManualRole.Owner || organizationRole === OrganizationRole.Owner;
		this.message = this.isAuthorized ? `Add Member to ${entityType}` : `${entityType} Owner or Organization ${OrganizationRole.Owner} Role required to Add Member to ${entityType}`;
	}
}
