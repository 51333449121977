import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../procedure-status.type';

export class ProcedureUpdateDescriptorsPermission {
	public isAuthorized: boolean;
	public nameMessage: string;
	public descriptionMessage: string;
	public detailMessage: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;
		const mayUpdate: boolean = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);

		this.isAuthorized = isEditable && mayUpdate;
		this.nameMessage = this.isAuthorized ? `Rename ${entityType}` :
			mayUpdate ? `${procedureStatus} ${entityType} may not be renamed` :
				`Manual Owner or Manager Role required to rename ${entityType}`;
		this.descriptionMessage = this.isAuthorized ? 'Update description' :
			mayUpdate ? `Unarchive ${entityType} to update description` :
				'Manual Owner or Manager Role required to update description';
		this.detailMessage = this.isAuthorized ? `Enter details/instructions unique to this ${entityType}` :
			mayUpdate ? `$Unarchive ${entityType} to update detail` :
				'Manual Owner or Manager Role required to update detail';
	}
}
