import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';


export class OrganizationCreatePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(requestingUserIsTargetUser: boolean, ownedOrganizationOnFreePlanCount: number, newOrganizationIsOnFreeBillingPlan: boolean) {
		const entityType = startCase(DomainEntityTypeName.Organization);

		this.isAuthorized = requestingUserIsTargetUser && (ownedOrganizationOnFreePlanCount === 0 || !newOrganizationIsOnFreeBillingPlan);
		this.message = this.isAuthorized ? `Create ${entityType}` : requestingUserIsTargetUser ? 'Only 1 free Organization allowed per User' : `Only the Owner of the User account may create a ${entityType}`;
	}
}
