import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ManualRole } from '../../manual/manual.types';
import { ProcedureStatus } from '../../procedure/procedure-status.type';

export class ProcedureStepDeletePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.ProcedureStep);
		const isEditable: boolean = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) && isEditable;
		this.message = this.isAuthorized ? `Delete ${entityType}, any substeps, & exclusively linked Content` : isEditable ? `Manual Owner or Manager Role required to Delete a ${entityType}` :
			`${procedureStatus} Procedures may not have Procedure Steps removed.`;
	}
}
