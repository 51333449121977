import { startCase } from 'lodash-unified';

import { ManualRole } from '../manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';


export class ManualUpdateDescriptorsPermission {
	public isAuthorized: boolean;
	public nameMessage: string;
	public descriptionMessage: string;

	public constructor(manualRole: ManualRole) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);
		this.isAuthorized = manualRole === ManualRole.Owner;
		this.nameMessage = this.isAuthorized ? `Rename ${entityType}` : `Manual Owner Role required to Rename a ${entityType}`;
		this.descriptionMessage = this.isAuthorized ? 'Update description' : `Manual Owner Role required to update a ${entityType} description`;
	}
}
