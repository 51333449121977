import { DomainEntityBase } from '../../core/domain/domain-entity-base.model';
import { DomainEntityTypeName } from '../../core/domain/domain-entity-type-name.types';
import { newEntityId } from '../../core/domain/entity-id.methods';
import { OmitMethodKeys } from '../../core/infrastructure/types/utility.types';
import { ContentType } from './content-type.type';
import { populate } from '../../core/infrastructure/methods/populate.method';
import { IContentDocument } from './content-document.type';


/** Data Transfer Object of `Content`
 *  Methods removed & properties marked optional to create accurate GraphQL DTO of Domain Entity
 *
 *  @ToDo: consider renaming with `DTO` suffix
 */
export interface IContent extends Partial<OmitMethodKeys<Content>> {
	/** client-only virtual property for select `ContentType.Attached` media types resolved by ApolloClient local resolver */
	previewURL?: string;
}

/** domain model class: Embedded (HTML), Attached (file), or Linked (URL) resource linked to 1 or more Procedures or Steps (and transitively, to corresponding Assignments or Tasks). */
export class Content extends DomainEntityBase {
	public type: ContentType;
	public mediaType: string;
	public name: string;
	public description?: string;
	/** Generated by client to provide preview image URL for `ContentType.Linked` */
	public preview?: string;
	public payload: string;
	public procedureStepCount: number;
	public readonly __typename: DomainEntityTypeName.Content = DomainEntityTypeName.Content;

	public constructor(initialValues?: IContentDocument) {
		super();
		if (initialValues) {
			populate<IContent>(this, initialValues);
			this.procedureStepCount = initialValues.procedureAndStepIdList.length;
		}
		this._id = this._id || newEntityId();
	}
}
