import { find } from 'lodash-unified';

/** Recursive search for child object
 *  generic typing prevents TS7053 error due to index access
 */
export const getRecursiveChild = <Parent extends Record<string, any>, Child extends Record<string, any>>(
	parentObject: Parent,
	childListPropertyName: string,
	findByFunction: (child: Child) => boolean,
): Child => {
	if (!parentObject[childListPropertyName]) {
		return undefined;
	}
	const result = find<Child>(parentObject[childListPropertyName] as Child[], findByFunction);
	if (result) { // found in children
		return result;
	} else { // recursively search children's children
		for (const childDocument of parentObject[childListPropertyName]) {
			const foundInChildren = getRecursiveChild(childDocument, childListPropertyName, findByFunction);
			if (foundInChildren) {
				return foundInChildren;
			}
		}
	}
};

/** Find function for `getRecursiveChild` matching on given id by default */
export const findByPropertyValue = <ObjectType extends Record<string, any>, ValueType = string>(value: ValueType, propertyName: string = '_id'): (objectToTest: ObjectType) => boolean => {
	return (objectToTest: ObjectType): boolean => {
		return objectToTest[propertyName] === value;
	};
};
