import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LOCATION } from '@ng-web-apis/common';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUser } from '@tasktrain/shared';

import { IUserRead_ResponseData } from '../../core/gql-operations/user/user-read.query';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { IUserAuthorizeOAuthClient_ResponseData } from '../../core/gql-operations/user/user-authorize-oauth-client.mutation';
import { UserReadService } from '../../core/gql-operations/user/user-read.service';


interface IURLParameters {
	name: string;
	client_id: string;
	redirect_uri: string;
	response_type: string;
	state: string;
}

@Component({
	selector: 'tt-user-authorize-oauth-client',
	templateUrl: 'user-authorize-oauth-client.component.html',
	styleUrls: ['user-authorize-oauth-client.component.scss'],
})
export class UserAuthorizeOAuthClientComponent implements OnInit {
	public waitingForServer = false;
	public clientName: string;
	public authorizationComplete = false;
	public username: string;
	private clientId: string;
	private userId: string;
	private oAuthState: string;

	public constructor(
		@Inject(LOCATION) private readonly location: Location,
		private activatedRoute: ActivatedRoute,
		private viewRouter: Router,
		private apollo: Apollo,
		private userMutationsService: UserMutationsService,
		private userReadService: UserReadService,
	) {
	}

	public ngOnInit(): void {
		zip(
			this.activatedRoute.queryParams,
			this.userReadService.fetch().pipe(
				map(({ data: { UserRead } }: FetchResult<IUserRead_ResponseData>) => {
					return UserRead;
				}),
			),
		).subscribe({
			next: ([params, user]: [Params, IUser]): void => {
				this.clientName = params.name;
				this.clientId = (params.client_id as string).toLowerCase();
				this.oAuthState = params.state;
				this.userId = user._id;
				this.username = user.username;
			},
		});
	}

	public onAuthorizeButtonPress(): void {
		if (!this.waitingForServer) {
			this.waitingForServer = true;

			this.userMutationsService.authorizeOAuthClient({
				clientId: this.clientId,
			}).subscribe({
				next: ({ data }: FetchResult<IUserAuthorizeOAuthClient_ResponseData>): void => {
					this.authorizationComplete = true;
					this.waitingForServer = false;
					this.location.replace(`${data.UserAuthorizeOAuthClient}&state=${this.oAuthState}`);
				},
				error: (error: unknown): void => {
					this.waitingForServer = false;
				},
			});
		}
	}

	public onCancelButtonPress(): void {
		this.viewRouter.navigateByUrl('/');
	}
}
