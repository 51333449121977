/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserSignOut_Mutation = gql`
	mutation UserSignOut {
		UserSignOut
	}
`;

export const UserSignOut_Name = (UserSignOut_Mutation.definitions[0] as OperationDefinitionNode).name?.value;


export interface IUserSignOut_RequestVariables {

}

export interface IUserSignOut_ResponseData {
	UserSignOut: boolean;
}
