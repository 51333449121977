/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const MutationInProgressListRead_Query = gql`
	query MutationInProgressListRead {
		MutationInProgressListRead @client {
			_id
			name
			variables
			status
		}
	}
`;

export const MutationInProgressListRead_Name = (MutationInProgressListRead_Query.definitions[0] as OperationDefinitionNode).name?.value;

export interface IMutationInProgress {
	_id: string;
	name: string;
	variables: Record<string, unknown>;
	status: 'Pending' | 'Success' | 'Error';
	__typename: 'MutationInProgress';
}

export interface IMutationInProgressListRead_ResponseData {
	MutationInProgressListRead: IMutationInProgress[];
}
