import { Injectable } from '@angular/core';
import { ExtraSubscriptionOptions, Subscription, SubscriptionResult } from 'apollo-angular';
import { SubscriptionOptionsAlone } from 'apollo-angular/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


export interface ITrackedSubscription<ResponseType = unknown, RequestVariables = Record<string, any>> {
	key: {
		variables: RequestVariables;
		options?: SubscriptionOptionsAlone<RequestVariables, ResponseType>;
		extra?: ExtraSubscriptionOptions;
	};
	subscription: Observable<SubscriptionResult<ResponseType>>;
}

/** Base class for GQL Subscriptions: extend & assign `document` property in derived classes */
@Injectable({ providedIn: 'root' })
export abstract class GQLClientSubscriptionService<ResponseType = unknown, RequestVariables = Record<string, unknown>> extends Subscription<ResponseType, RequestVariables> {
	public subscribe(
		variables?: RequestVariables,
		options?: SubscriptionOptionsAlone<RequestVariables, ResponseType>,
		extra?: ExtraSubscriptionOptions,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		sideEffectMethod: (subscriptionResult: SubscriptionResult<ResponseType>) => void = () => {
		},
	): Observable<SubscriptionResult<ResponseType>> {
		return super.subscribe(variables, options, extra).pipe(tap(sideEffectMethod));
	}
}
