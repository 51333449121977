/** https://www.iana.org/assignments/media-types/media-types.xhtml */
export enum MediaType {
	HTML = 'text/html',
	TextPlain = 'text/plain'
}

export enum MediaTypePrefix { IMAGE = 'image', AUDIO = 'audio', VIDEO = 'video', TEXT = 'text', APPLICATION = 'application' }

/** https://www.w3schools.com/tags/att_input_accept.asp */
export const fileUploadAcceptMediaTypes =
	'video/*' + ',' +
	'image/*' + ',' +
	'audio/*' + ',' +
	'text/*' + ',' +
	'application/ogg' + ',' +
	'application/pdf' + ',' +
	'application/vnd.apple.keynote' + ',' + '.key' + ',' +
	'application/vnd.apple.numbers' + ',' + '.numbers' + ',' +
	'application/vnd.apple.pages' + ',' + '.pages' + ',' +
	'application/msword' + ',' +
	'application/vnd.ms-excel' + ',' +
	'application/vnd.ms-powerpoint' + ',' +
	'application/vnd.ms-project' + ',' +
	'application/vnd.ms-works' + ',' +
	'application/vnd.ms-xpsdocument' + ',' +
	'application/vnd.openxmlformats-officedocument.presentationml.presentation' + ',' +
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' + ',' +
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document' + ',' +
	'application/zip' + ',' + '.rar' + ',' + '.7zip';
