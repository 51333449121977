import { APP_BOOTSTRAP_LISTENER, ComponentRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApolloModule } from 'apollo-angular';
import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from 'ngx-google-analytics';
import { popperVariation, provideTippyConfig } from '@ngneat/helipopper';


import { environment } from '@environment/browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { GQLClientService } from './core/services/gql/gql-client.service';
import { SharedEagerModule } from './shared-eager/shared-eager.module';
import { AnalyticsService } from './core/services/analytics.service';


@NgModule({
	// Providers: Services and Values available to the Angular dependency injector
	providers: [
		{
			provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
			useValue: {
				trackingCode: environment.GoogleAnalyticsCode,
			},
		},
		{
			provide: APP_BOOTSTRAP_LISTENER,
			multi: true,
			useFactory: (analyticsService: AnalyticsService) => {
				return (component: ComponentRef<unknown>) => {
					analyticsService.initializeGoogleAnalytics(component);
				};
			},
			deps: [AnalyticsService],
		},
		provideTippyConfig({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: {
					theme: 'tasktrain',
					arrow: true,
					animation: 'scale',
					delay: [555, 0],
				},
				popper: popperVariation,
			},
		}),
	],
	// Declarations: Components, Pipes, Directives belonging to this module & thereby available to others in this module
	declarations: [
		AppComponent,
	],
	// Imports: Other Modules whose Declarations should be available to components in this module
	imports: [
		ApolloModule,
		AuthModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.serverHost !== 'localhost' }),
		SharedEagerModule.forRoot(),
		AppRoutingModule, // must load after `AuthModule` so `auth` routes are defined
	],
	// Exports: Components, Pipes, Directives to make available to components declared in Modules importing this module
	exports: [],
	// Elements and properties other than components or directives
	schemas: [],
	// Bootstrap: initial component to load when initializing application
	bootstrap: [AppComponent],
})
/** Angular root module */
export class AppModule {
	public constructor(
		private gqlClientService: GQLClientService, // Inject to instantiate & start
	) {
	}
}
