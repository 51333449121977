import { startCase } from 'lodash-unified';

import { OrganizationRole } from '../organization-role.type';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class OrganizationAddMemberPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(organizationRole: OrganizationRole, isVerified: boolean) {
		const entityType = startCase(DomainEntityTypeName.Organization);

		this.isAuthorized = organizationRole === OrganizationRole.Owner && isVerified === true;
		this.message = this.isAuthorized ? `Add Members to ${entityType}` : 'Only Owner accounts with verified e-mail addresses may add Organization Members.';
	}
}
