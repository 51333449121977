import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';

export class ProcedureMoveToManualPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(
		sourceManualRole: ManualRole,
		targetManualRole: ManualRole,
		targetManualIsEqualToSource: boolean,
		isCopyProtected: boolean,
		hasLinkedAssignments: boolean,
		targetOrganizationIsEqualToSource: boolean,
	) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);

		this.isAuthorized = (sourceManualRole === ManualRole.Owner || sourceManualRole === ManualRole.Manager) &&
			(targetManualRole in ManualRole) &&
			!targetManualIsEqualToSource &&
			!isCopyProtected &&
			!(hasLinkedAssignments && !targetOrganizationIsEqualToSource);

		switch (true) {
			case this.isAuthorized:
				this.message = 'Move to another Manual';
				break;

			case isCopyProtected:
				this.message = `Copy protected ${entityType} may not be moved`;
				break;

			case targetManualIsEqualToSource:
				this.message = `${entityType} may not be moved into same target`;
				break;

			case (hasLinkedAssignments && !targetOrganizationIsEqualToSource):
				this.message = `${entityType} with linked Assignments may only be Copied, not Moved, to a different Organization.`;
				break;

			default:
				this.message = `Manual Role required in destination Manual to move a ${entityType}`;
				break;
		}
	}
}
