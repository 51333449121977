/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserAuthorizeOAuthClient_Mutation = gql`
	mutation UserAuthorizeOAuthClient($clientId: String!){
		UserAuthorizeOAuthClient(clientId: $clientId)
	}
`;

export const UserAuthorizeOAuthClient_Name = (UserAuthorizeOAuthClient_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserAuthorizeOAuthClient_RequestVariables {
	clientId: string;
}

export interface IUserAuthorizeOAuthClient_ResponseData {
	UserAuthorizeOAuthClient: string;
}
