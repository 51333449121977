/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAccount, OrganizationRole } from '@tasktrain/shared';


export const AccountUpdateRole_Mutation = gql`
	mutation AccountUpdateRole($accountId: ID!, $organizationRole: OrganizationRole!) {
		AccountUpdateRole(accountId: $accountId, organizationRole: $organizationRole ) {
			_id
			organizationRole
		}
	}
`;

export const AccountUpdateRole_Name = (AccountUpdateRole_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateRole_ResponseData {
	AccountUpdateRole: IAccount;
}

export interface IAccountUpdateRole_RequestVariables {
	accountId: string;
	organizationRole: OrganizationRole;
}
