import { ManualRole } from '../../../library/manual/manual.types';


export class AssignmentUpdateRequestorPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, isAssignmentOrTaskAssignee: boolean) {
		this.isAuthorized = ((manualRole === ManualRole.Owner || manualRole === ManualRole.Manager) || isAssignmentOrTaskAssignee);
		this.message = this.isAuthorized ? 'Update Assignment Requestor' : 'Only Assignment or Task Assignee or Manual Owner or Manager may update Assignment Requestor';
	}
}
