/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserSetPassword_Mutation = gql`
	mutation UserSetPassword($password: String!, $token: String!){
		UserSetPassword(password: $password, token: $token)
	}
`;

export const UserSetPassword_Name = (UserSetPassword_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserSetPassword_RequestVariables {
	password: string;
	token: string;
}

export interface IUserSetPassword_ResponseData {
	UserSetPassword: boolean;
}
