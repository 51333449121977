import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppRoutingPaths } from '../../app-routing.module';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';
import { AuthenticationService } from '../../core/services/authentication.service';


@Injectable({
	providedIn: 'root',
})
export class AuthRoutingGuardService {
	public constructor(
		private viewRouter: Router,
		private userMutationsService: UserMutationsService,
		private authenticationService: AuthenticationService,
	) {
	}

	public async canActivate(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean> {
		if (await this.authenticationService.isSignedIn()) {
			this.userMutationsService.signOut().subscribe();
		}
		return true;
	}

	public canActivateChild(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return this.authorizeOrRedirect(activatedView, viewState);
	}

	private async authorizeOrRedirect(activatedView: ActivatedRouteSnapshot, viewState: RouterStateSnapshot): Promise<boolean | UrlTree> {
		if (await this.authenticationService.isSignedIn()) {
			return this.viewRouter.parseUrl(AppRoutingPaths.Main);
		} else {
			return true;
		}
	}
}
