/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserUpdatePassword_Mutation = gql`
	mutation UserUpdatePassword($password: String!){
		UserUpdatePassword(password: $password)
	}
`;

export const UserUpdatePassword_Name = (UserUpdatePassword_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserUpdatePassword_RequestVariables {
	password: string;
}

export interface IUserUpdatePassword_ResponseData {
	UserUpdatePassword: boolean;
}
