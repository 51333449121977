/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { ICommentThreadWithAccountViewStatus } from '@tasktrain/shared';


export const AccountUpdateCommentThreadArchiveStatus_Mutation = gql`
	mutation AccountUpdateCommentThreadArchiveStatus($accountId: ID!, $commentThreadId: ID!, $isArchived: Boolean!) {
		AccountUpdateCommentThreadArchiveStatus(accountId: $accountId, commentThreadId: $commentThreadId, isArchived: $isArchived) {
			_id
			viewStatus
		}
	}
`;

export const AccountUpdateCommentThreadArchiveStatus_Name = (AccountUpdateCommentThreadArchiveStatus_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateCommentThreadArchiveStatus_RequestVariables {
	accountId: string;
	commentThreadId: string;
	isArchived: boolean;
}

export interface IAccountUpdateCommentThreadArchiveStatus_ResponseData {
	AccountUpdateCommentThreadArchiveStatus: ICommentThreadWithAccountViewStatus;
}
