import { startCase } from 'lodash-unified';

import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { OrganizationRole } from '../../../common/organization/organization-role.type';

export class ManualReadPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(organizationRole: OrganizationRole) {
		const entityType: string = startCase(DomainEntityTypeName.Manual);

		this.isAuthorized = organizationRole in OrganizationRole;
		this.message = this.isAuthorized ? `View ${entityType}` : `Organization Role required to view ${entityType}`;
	}
}
