/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult } from '@tasktrain/shared';


export const AssignmentDelete_Mutation = gql`
	mutation AssignmentDelete($assignmentId: ID!, $accountId: ID!) {
		AssignmentDelete(assignmentId: $assignmentId, accountId: $accountId) {
			objectTypeName
			objectId
		}
	}
`;

export const AssignmentDelete_Name = (AssignmentDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAssignmentDelete_RequestVariables {
	assignmentId: string;
	accountId: string;
}

export interface IAssignmentDelete_ResponseData {
	AssignmentDelete: DeletedObjectResult;
}
