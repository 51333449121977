import { ManualRole } from '../../manual';
import { startCase } from 'lodash-unified';
import { DomainEntityTypeName } from '../../../core';


export class ProcedureUpdateImagePermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);

		this.isAuthorized = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		this.message = this.isAuthorized ? 'Update image' : `Manual Owner or Manager Role required to update ${entityType} image`;
	}
}
