/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserAuthorizeRegistration_Mutation = gql`
	mutation UserAuthorizeRegistration($username: String!){
		UserAuthorizeRegistration(username: $username)
	}
`;

export const UserAuthorizeRegistration_Name = (UserAuthorizeRegistration_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserAuthorizeRegistration_RequestVariables {
	username: string;
}

export interface IUserAuthorizeRegistration_ResponseData {
	UserAuthorizeRegistration: boolean;
}
