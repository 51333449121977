import { startCase } from 'lodash-unified';

import { ManualRole } from '../../manual/manual.types';
import { DomainEntityTypeName } from '../../../core/domain/domain-entity-type-name.types';
import { ProcedureStatus } from '../../procedure/procedure-status.type';


export class ProcedureStepUpdateContentListOrderPermission {
	public isAuthorized: boolean;
	public message: string;

	public constructor(manualRole: ManualRole, procedureStatus: ProcedureStatus) {
		const entityType: string = startCase(DomainEntityTypeName.Procedure);
		const entityChildType: string = startCase(DomainEntityTypeName.Content);
		const mayUpdate: boolean = (manualRole === ManualRole.Owner || manualRole === ManualRole.Manager);
		const isEditable = procedureStatus === ProcedureStatus.Draft || procedureStatus === ProcedureStatus.Published;

		this.isAuthorized = mayUpdate && isEditable;
		this.message = this.isAuthorized ? `Reorder ${entityChildType}` :
			isEditable ? `Manual Owner or Manager permission required to reorder ${entityChildType}` :
				`${procedureStatus} ${entityType} description may not be updated.`;
	}
}
