/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { IAccount } from '@tasktrain/shared';

export const AccountUpdateEmailNotificationTypeList_Mutation = gql`
	mutation AccountUpdateEmailNotificationTypeList($accountId: ID!, $emailNotificationTypeList: [EmailNotificationType!]!) {
		AccountUpdateEmailNotificationTypeList(accountId: $accountId, emailNotificationTypeList: $emailNotificationTypeList) {
			_id
			emailNotificationTypeList
		}
	}
`;

export const AccountUpdateEmailNotificationTypeList_Name = (AccountUpdateEmailNotificationTypeList_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IAccountUpdateEmailNotificationTypeList_RequestVariables {
	accountId: string;
	emailNotificationTypeList: string[];
}

export interface IAccountUpdateEmailNotificationTypeList_ResponseData {
	AccountUpdateEmailNotificationTypeList: IAccount;
}
