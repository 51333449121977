/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';


export const UserRequestVerification_Mutation = gql`
	mutation UserRequestVerification {
		UserRequestVerification
	}
`;

export const UserRequestVerification_Name = (UserRequestVerification_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IUserRequestVerification_ResponseData {
	UserRequestVerification: boolean;
}
